import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilServiceService } from '../../service/utilService.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

@Component({
  selector: 'app-image-upload-draw',
  templateUrl: './image-upload-draw.component.html',
  styleUrls: ['./image-upload-draw.component.css'],
})
export class ImageUploadDrawComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  ctx: CanvasRenderingContext2D;
  drawing = false;
  currentX = 0;
  currentY = 0;
  imageUploaded: boolean = false;
  showCanvas: boolean = false; // Flag to control canvas visibility
  loading: boolean = false;
  hideButtons: boolean = true;
  // ngx-webcam related variables
  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public webcamImage: WebcamImage = null;
  public errors: WebcamInitError[] = [];
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();

  constructor(
    public activeModal: NgbActiveModal,
    private utilService: UtilServiceService,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    // Check if multiple webcams are available
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
  }

  ngAfterViewInit() {
    this.setupDrawingEvents();
  }

  // Image upload handler
  onImageUpload(event: any) {
    this.hideButtons = false;
    this.showWebcam = false;
    const reader = new FileReader();
    const img = new Image();

    reader.onload = (e: any) => {
      img.src = e.target.result;
      img.onload = () => {
        this.resizeCanvas(img.width, img.height);
        this.imageUploaded = true;
        this.showCanvas = true;
        this.ctx = this.canvas.nativeElement.getContext('2d')!;
        this.ctx.drawImage(
          img,
          0,
          0,
          this.canvas.nativeElement.width,
          this.canvas.nativeElement.height
        );
      };
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  // Open Camera for photo capture
  openCamera() {
    this.showCanvas = false;
    this.hideButtons = false;
    this.showWebcam = true;
  }

  // Handle capturing the image from webcam
  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.showWebcam = false;
    this.displayCapturedImage(webcamImage.imageAsDataUrl);
  }

  private displayCapturedImage(imageDataUrl: string) {
    const img = new Image();
    img.src = imageDataUrl;

    img.onload = () => {
      this.resizeCanvas(img.width, img.height);
      this.showCanvas = true;
      this.ctx = this.canvas.nativeElement.getContext('2d')!;
      this.ctx.drawImage(
        img,
        0,
        0,
        this.canvas.nativeElement.width,
        this.canvas.nativeElement.height
      );
    };
  }

  // Resize the canvas based on the image size
  resizeCanvas(width: number, height: number) {
    const aspectRatio = width / height;

    this.canvas.nativeElement.width = Math.min(
      this.canvas.nativeElement.parentElement!.clientWidth,
      width
    );
    this.canvas.nativeElement.height =
      this.canvas.nativeElement.width / aspectRatio;
  }

  // Setup canvas drawing
  setupDrawingEvents() {
    const canvasEl = this.canvas.nativeElement;

    canvasEl.addEventListener('mousedown', (e) =>
      this.startDrawing(e.clientX, e.clientY)
    );
    canvasEl.addEventListener('mousemove', (e) =>
      this.draw(e.clientX, e.clientY)
    );
    canvasEl.addEventListener('mouseup', () => this.stopDrawing());
    canvasEl.addEventListener('mouseout', () => this.stopDrawing());

    canvasEl.addEventListener('touchstart', (e) =>
      this.startDrawing(e.touches[0].clientX, e.touches[0].clientY)
    );
    canvasEl.addEventListener('touchmove', (e) =>
      this.draw(e.touches[0].clientX, e.touches[0].clientY)
    );
    canvasEl.addEventListener('touchend', () => this.stopDrawing());
  }

  startDrawing(x: number, y: number) {
    this.drawing = true;
    this.currentX = x - this.canvas.nativeElement.getBoundingClientRect().left;
    this.currentY = y - this.canvas.nativeElement.getBoundingClientRect().top;
  }

  draw(x: number, y: number) {
    if (!this.drawing) return;
    const newX = x - this.canvas.nativeElement.getBoundingClientRect().left;
    const newY = y - this.canvas.nativeElement.getBoundingClientRect().top;

    this.ctx.beginPath();
    this.ctx.moveTo(this.currentX, this.currentY);
    this.ctx.lineTo(newX, newY);
    this.ctx.strokeStyle = 'red';
    this.ctx.lineWidth = 2;
    this.ctx.stroke();
    this.ctx.closePath();

    this.currentX = newX;
    this.currentY = newY;
  }

  stopDrawing() {
    this.drawing = false;
  }

  saveImage() {
    const imageData = this.canvas.nativeElement.toDataURL('image/png');
    const byteString = atob(imageData.split(',')[1]);
    const mimeString = imageData.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeString });
    const file = new File([blob], 'uploaded_image.png', { type: mimeString });

    let data = {
      base64: imageData,
      file: file,
    };

    this.uploadFile(data);
  }

  uploadFile(data) {
    const form = new FormData();
    form.append('file', data.file);
    this.loading = true;
    this.utilService.uploadFileInspection(form).subscribe({
      next: (response) => {
        this.loading = false;
        let finalData = {
          base64: data.base64,
          file: response.data,
        };
        this.activeModal.close(finalData);
      },
      error: (error) => {
        this.loading = false;
      },
    });
  }

  // Get the trigger observable
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  // Switch to next webcam (if available)
  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
}
