import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppInnerLayoutComponent } from './blocks/layouts/app-inner-layout/app-inner-layout.component';
import { AppOuterLayoutComponent } from './blocks/layouts/app-outer-layout/app-outer-layout.component';
import { AuthRoutes } from './core/routes/auth.routes';
import { UnAuthRoutes } from './core/routes/unauth.routes';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthenticationErrorComponent } from './modules/error/pages/authentication-error/authentication-error.component';
import { SampleComponent } from './shared/components/sample/sample.component';
import { InspectionComponent } from './shared/components/inspection/inspection.component';

const routes: Routes = [
  {
    path: '',
    component: AppInnerLayoutComponent,
    children: AuthRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: AppOuterLayoutComponent,
    children: UnAuthRoutes,
  },
  {
    path: 'cix/:checklistId/:identifier',
    component: InspectionComponent,
  },
  {
    path: 'errors',
    loadChildren: () =>
      import('src/app/modules/error/error.module').then((m) => m.ErrorModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
