import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { User } from 'src/app/modules/user/models/user.models';
import { UserService } from 'src/app/modules/user/services/user.service';
import { ImageService } from '../../service/image.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Component({
  selector: 'app-avatar-list',
  templateUrl: './avatar-list.component.html',
  styleUrls: ['./avatar-list.component.css'],
})
export class AvatarListComponent implements OnInit {
  @Input() userList: User[];
  @Input() selectedList: string[];
  @Input() buttonName: string = 'Users';
  @Input() editable: boolean = true;
  @Output() add = new EventEmitter<string>();
  @Output() remove = new EventEmitter<string>();

  @Input() size: string = '30';
  @Input() textSizeRatio: number = 3;
  @Input() initialsSize: number = 3;
  private previousSelectedList: string[] = [];
  public userAvatarInfoList: User[] = [];
  currentUser: any;
  constructor(
    private userService: UserService,
    private imageService: ImageService,
    private authService: AuthService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['selectedList'] &&
      Array.isArray(changes['selectedList'].currentValue) &&
      !this.areArraysEqual(changes['selectedList'].currentValue, this.previousSelectedList)
    ) {
      this.previousSelectedList = [...changes['selectedList'].currentValue]; // Save a copy of the array
      this.selectedList = changes['selectedList'].currentValue;
      this.userAvatarInfoList = [];
      this.fetchUserInfo();
    }
  }

  private areArraysEqual(arr1: string[], arr2: string[]): boolean {
    return (
      arr1?.length === arr2?.length &&
      arr1?.every((value, index) => value === arr2[index])
    );
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getCurrentUserId();
    // this.fetchUserInfo();
  }

  addUser(userId: string) {
    this.add.emit(userId);
  }

  removeUser(userId: string) {
    this.remove.emit(userId);
  }

  fetchUserInfo() {
    if (this.selectedList?.length > 0) {
      this.selectedList?.forEach((userId) => {
        this.userService.getUserInfoCached(userId.toString()).subscribe({
          next: (response) => {
            let user = response;
            user.displayName =
              user?.firstName + ' ' + (user?.lastName ? user.lastName : '-');
            this.userAvatarInfoList.push(user);
          },
          error: (error) => {
            console.error(error);
          },
        });
      });
    }
  }

  getUserImage(path: string) {
    return this.imageService.getImage(path);
  }
}
