import { LocalDate } from 'local-date';
import * as dayjs from 'dayjs';
import {
  DateFilter,
  DateRangeSelected,
  Interval,
  RANGE_CAT_LIST,
  Ranges,
  dateRangeFilter,
} from 'src/app/core/models/common.models';
import { SessionConstants } from 'src/app/core/constants/session.constants';
import { DateTimeFormat } from 'src/app/modules/campaign/models/campaign.models';

// export default class DateUtils {
//   static datePipe: DatePipe
   
//   constructor(){}
import { Component, Injectable } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { Frequencies } from 'src/app/modules/budget/models/budget.models';

interface MonthInfo {
  name: string;
  index: number;
}

export enum MonthFilterType {
  WHOLE_YEAR = 'WHOLE_YEAR',
  H1 = 'H1',
  H2 = 'H2',
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

@Injectable({
  providedIn: 'root',
})
export default class DateUtils {

  static getEndOfDayInMillis(): number {
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay.getTime();
  }

  static toLocalDate(date: Date): string {
    // Date.parse(Your_Date, 'dd/MM/yyyy').toString('MM/dd/yyyy')
    var localdate = new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    }).format(date);
    return localdate;
  }
  static toLocalDateWithFormat(date: Date, format: string): string {
    return formatDate(date, format, 'en-US');
  }

  static isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  static getMillisecondsAtStartOfDay(): number {
    const now = new Date();
    const startOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0,
      0
    ); // Set the time to 00:00:00:000
    return startOfDay.getTime();
  }

  // milliseconds to end of the day (today)
  static getMillisecondsAtEndOfDay(): number {
    const now = new Date();
    const endOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59,
      999
    ); // Set the time to 23:59:59:999
    return endOfDay.getTime();
  }

  static getMillis() {
    return new Date().getTime();
  }

  static getDate(): Date {
    return new Date();
  }
  static getDateFromMillis(millis: number): Date {
    return new Date(millis);
  }

  static getLocalDate(): LocalDate {
    return new LocalDate();
  }

  static getLocalDateByParam(day: string) {
    return new LocalDate(day);
  }

  static getLocalDateType(day: number): LocalDate {
    return new LocalDate(day);
  }
  static subDaysToDate(date: Date, days: number): Date {
    date.setDate(date.getDate() - days);
    return date;
  }

  static subDaysToLocalDate(
    localdate: LocalDate,
    numberOfDays: number
  ): LocalDate {
    var newLocalDate = new LocalDate(localdate);
    newLocalDate.setDate(newLocalDate.getDate() - numberOfDays);
    return newLocalDate;
  }

  static currentTimeInMillis(input: LocalDate) {
    return input.setHours(0, 0, 0, 0);
  }

  // using date
  static substractDaysToDate(date: Date, days: number): Date {
    date.setDate(date.getDate() - days);
    return date;
  }

  static addDaysToDate(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  static calculateDiff(dateSent: Date) {
    let currentDate = new Date();
    return Math.floor(
      (Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ) -
        Date.UTC(
          dateSent.getFullYear(),
          dateSent.getMonth(),
          dateSent.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
  }

  static getWeekNumber(date: any = 'current') {
    let currentDate: any = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (date != 'current') {
      currentDate = date;
    }

    let startDate: any = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

    var weekNumber = Math.ceil(days / 7);

    return weekNumber;
  }

  // start date and end date of last week
  static getLastWeek() {
    const date = new Date();
    var to = date.setTime(
      date.getTime() - (date.getDay() ? date.getDay() : 7) * 24 * 60 * 60 * 1000
    );
    var from = date.setTime(date.getTime() - 6 * 24 * 60 * 60 * 1000);
    return [from, to];
  }

  //  start date of yesterday
  static getDateOfYesterday(): Date {
    const date = new Date();
    return new Date(date.getTime() - 24 * 60 * 60 * 1000);
  }
  // last 7
  static getDateOfLastSevenDay(): Date {
    const date = new Date();
    return new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
  }

  //   last 30 days

  static getStartDateOfLastThirty(): Date {
    const date = new Date();
    return new Date(Date.now() - 29 * 24 * 60 * 60 * 1000);
  }

  // start date of this month
  static getStartDateOfThisMonth(): Date {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  // end date of this month
  static getEndDateOfThisMonth(): Date {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  // start date of last month
  static getStartDateOfLastMonth(): Date {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() - 1, 1);
  }

  // end date of last month
  static getEndDateOfLastMonth(): Date {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 0);
  }

  // start day to timestamp
  static getStartOfDayInMillisFrom(input: LocalDate): number {
    return input.setHours(0, 0, 0, 0);
  }

  // end day to timestamp
  static getEndOfDayInMillisFrom(input: LocalDate): number {
    return input.setHours(23, 59, 59, 999);
  }

  // start date of this year
  static getStartDateOfThisYear(): Date {
    const currentYear = new Date().getFullYear();
    return new Date(currentYear, 0, 1);
  }

  // end date of this year
  static getEndDateOfThisYear(): Date {
    const currentYear = new Date().getFullYear();
    return new Date(currentYear, 11, 31);
  }

  // start date of last year
  static getStartDateOfLastYear(): Date {
    const currentYear = new Date().getFullYear();
    return new Date(currentYear - 1, 0, 1);
  }

  // end date of last year
  static getEndDateOfLastYear(): Date {
    const currentYear = new Date().getFullYear();
    return new Date(currentYear - 1, 11, 31);
  }

  static localDateToString(day: LocalDate) {
    let stringDate = day.toISOString();
    return stringDate;
  }

  static dayOfWeekFromMillis(date: Date) {
    var weekday = [];
    weekday[0] = 'Sunday';
    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';
    return weekday[date.getDay()];
  }
  static dayOfWeek(dayOfWeek: number) {
    var weekday = [];
    weekday[0] = 'Sunday';
    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';
  }
  static monthOfyear(month: number): string {
    if (month == 1) return 'January';
    if (month == 2) return 'February';
    if (month == 3) return 'March';
    if (month == 4) return 'April';
    if (month == 5) return 'May';
    if (month == 6) return 'June';
    if (month == 7) return 'July';
    if (month == 8) return 'August';
    if (month == 9) return 'September';
    if (month == 10) return 'October';
    if (month == 11) return 'November';
    if (month == 12) return 'December';
    return '';
  }

  static GetDates(startDate: any, daysToAdd: any) {
    var aryDates = [];

    for (var i = 0; i < daysToAdd; i++) {
      var currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      aryDates.push(currentDate);
    }

    return aryDates;
  }

  static daysBetween(start: LocalDate, end: LocalDate): String[] {
    var newStartLocalDate = new LocalDate(start);
    var newEndLocalDate = new LocalDate(end);
    var days = (String[10] = []);

    while (newStartLocalDate < newEndLocalDate) {
      days.push(newStartLocalDate.toISOString());
      newStartLocalDate.setDate(newStartLocalDate.getDate() + 1);
    }
    return days;
  }

  static getNormalDateFormat() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // months are zero-indexed, so add 1 to get the actual month number
    const day = date.getDate();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  static YearMonthDayDateFormat(date: Date) {
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1); // months are zero-indexed, so add 1 to get the actual month number
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  static currentMilliSeconds() {
    return new Date().getTime();
  }

  static currentMilliSecondsByParams(date: any) {
    return new Date(date).getTime();
  }

  static addDaysToLocalDate(
    localdate: LocalDate,
    numberOfDays: number
  ): LocalDate {
    var newLocalDate = new LocalDate(localdate);
    newLocalDate.setDate(newLocalDate.getDate() + numberOfDays);
    return newLocalDate;
  }

  static setTimeToMilliSecond(date: Date, selectedTime: string) {
    const [hours, minutes] = selectedTime.split(':').map(Number);
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.getTime();
  }

  static setTimeToUTCFormat(dayInfo: DateTimeFormat) {
    const date = new Date(dayInfo.day);
    const [hoursFrom, minutesFrom] = dayInfo.to.split(':').map(Number);
    const [hoursTo, minutesTo] = dayInfo.to.split(':').map(Number);
    let dateFrom: any;
    let dateTo: any;
    dateFrom = date.setHours(hoursFrom);
    dateFrom = date.setHours(minutesFrom);
    dateTo = date.setHours(hoursTo);
    dateTo = date.setMinutes(minutesTo);
    return [dateFrom, dateTo]
  }

  static getDayTimeToTimeStamp(dayInfo: DateTimeFormat) {
    const date = new Date(dayInfo.day);
    let timeStampFrom: number;
    let timeStampTo: number;
    if (dayInfo.from) timeStampFrom = DateUtils.setTimeToMilliSecond(date, dayInfo.from)
    if (dayInfo.to) timeStampTo = DateUtils.setTimeToMilliSecond(date, dayInfo.to)
    return timeStampFrom + ',' + timeStampTo
  }



  static getDateRanges(range: string) {
    let ranges = new DateRangeSelected();
    switch (range) {
      case 'TODAY':
        ranges.startTime = DateUtils.currentTimeInMillis(DateUtils.getDate());
        ranges.endTime = DateUtils.getEndOfDayInMillis();
        break;
      case 'YESTERDAY':
        ranges.startTime = DateUtils.getStartOfDayInMillisFrom(
          DateUtils.getDateOfYesterday()
        );
        ranges.endTime = DateUtils.getEndOfDayInMillisFrom(
          DateUtils.getDateOfYesterday()
        );
        break;
      case 'LAST_7_DAYS':
        ranges.startTime = DateUtils.getStartOfDayInMillisFrom(
          DateUtils.getDateOfLastSevenDay()
        );
        ranges.endTime = DateUtils.getEndOfDayInMillis();
        break;
      case 'LAST_30_DAYS':
        ranges.startTime = DateUtils.getStartOfDayInMillisFrom(
          DateUtils.getStartDateOfLastThirty()
        );
        ranges.endTime = DateUtils.getEndOfDayInMillis();
        break;
      case 'THIS_MONTH':
        ranges.startTime = DateUtils.getStartOfDayInMillisFrom(
          DateUtils.getStartDateOfThisMonth()
        );
        ranges.endTime = DateUtils.getEndOfDayInMillis();
        break;
      case 'LAST_MONTH':
        ranges.startTime = DateUtils.getStartOfDayInMillisFrom(
          DateUtils.getStartDateOfLastMonth()
        );
        ranges.endTime = DateUtils.getEndOfDayInMillisFrom(
          DateUtils.getEndDateOfLastMonth()
        );
        break;
      case 'OVERALL':
        const currentUserSession = JSON.parse(
          localStorage.getItem(
            localStorage.hasOwnProperty(SessionConstants.SESSION)
              ? SessionConstants.SESSION
              : SessionConstants.CALLBACK_SESSION
          )
        );
        ranges.startTime = currentUserSession.entityAddedOn;
        ranges.endTime = DateUtils.getEndOfDayInMillis();
        break;
    }
    return ranges;
  }

  static getstringDate(date: string) {
    return new Date(date);
  }

  static getCurrentTimeString() {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    // Format hours and minutes as a string (you can adjust the format based on your needs)
    const formattedTime = `${this.padZero(hours)}:${this.padZero(minutes)}`;
    // Update ngModel binding to set the current time
    return formattedTime;
  }
  static padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  static isCurrentDay(timestamp: number): boolean {
    const currentTime = DateUtils.currentMilliSeconds(); // Current timestamp in milliseconds
    const currentDay = new Date(currentTime).setHours(0, 0, 0, 0); // Set current time to the start of the day
    const inputDay = new Date(timestamp).setHours(0, 0, 0, 0); // Set input time to the start of the day
    return inputDay <= currentDay;
  }

  static isPastDay(timestamp: number): boolean {
    const currentTime = DateUtils.currentMilliSeconds(); // Current timestamp in milliseconds
    const currentDay = new Date(currentTime).setHours(0, 0, 0, 0); // Set current time to the start of the day
    const inputDay = new Date(timestamp).setHours(0, 0, 0, 0); // Set input time to the start of the day
    return inputDay < currentDay;
  }

  static dateFilter(dateEvent: any, defaultRange?: Ranges) {
    let payload = new dateRangeFilter();
    let interval = dateEvent?.range || defaultRange;
    let category = RANGE_CAT_LIST.find(
      (data) => data.interval === interval
    )?.category;
    let payloadInterval = new Interval();

    if (interval !== Ranges.CUSTOM && interval !== Ranges.OVERALL) {
      payloadInterval.interval = interval;
      payloadInterval.category = category;
      delete payload.date;
    }

    if (interval === Ranges.OVERALL) {
      payloadInterval.category = Ranges.OVERALL;
      delete payload.date;
    }

    if (interval === Ranges.CUSTOM) {
      payloadInterval.category = Ranges.CUSTOM;
      let date = new DateFilter();
      date.from = dateEvent.startTime;
      date.to = dateEvent.endTime;
      payload.date = date;
    }
    payload.interval = payloadInterval;
    return payload;
  }

  static formatMonth(month: number): string {
    if (month < 10) {
      return `0${month}`;
    } else {
      return `${month}`;
    }
  }
  static formatMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) {
      return `${remainingMinutes} minutes`;
    } else if (remainingMinutes === 0) {
      return `${hours} hours`;
    } else {
      return `${hours} hours and ${remainingMinutes} minutes`;
    }
  }

  static getMonthsList(financialFrequency: Frequencies): string[] {
    const startDate = new Date(financialFrequency.frequencyFrom + '-01');
    const endDate = new Date(financialFrequency.frequencyTo + '-01');

    const months: string[] = [];
    const current = new Date(startDate);

    while (current <= endDate) {
      const month = current.getMonth() + 1; // months are zero-indexed
      const year = current.getFullYear();
      months.push(`${year}-${month.toString().padStart(2, '0')}`);
      current.setMonth(current.getMonth() + 1);
    }

    return months;
  }

  static getMonthNameByIndex(index: number): string {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return monthNames[index];
  }

  static getDateRange(
    financialStartDate: Date,
    financialEndDate: Date,
    rangeType: MonthFilterType
  ) {
    let startDate: Date;
    let endDate: Date;
    switch (rangeType) {
      case MonthFilterType.WHOLE_YEAR:
        startDate = financialStartDate;
        endDate = financialEndDate;
        break;
      case MonthFilterType.H1:
        endDate = new Date(
          financialStartDate.getFullYear(),
          financialStartDate.getMonth() + 5,
          30
        );
        startDate = financialStartDate;
        break;
      case MonthFilterType.H2:
        startDate = new Date(
          financialStartDate.getFullYear(),
          financialStartDate.getMonth() + 6,
          1
        );
        endDate = financialEndDate;
        break;
      case MonthFilterType.Q1:
        startDate = financialStartDate;
        endDate = new Date(
          financialStartDate.getFullYear(),
          financialStartDate.getMonth() + 2,
          30
        );

        break;
      case MonthFilterType.Q2:
        startDate = new Date(
          financialStartDate.getFullYear(),
          financialStartDate.getMonth() + 3,
          30
        );
        endDate = new Date(
          financialStartDate.getFullYear(),
          financialStartDate.getMonth() + 5,
          30
        );
        break;
      case MonthFilterType.Q3:
        startDate = new Date(
          financialStartDate.getFullYear(),
          financialStartDate.getMonth() + 6,
          30
        );
        endDate = new Date(
          financialStartDate.getFullYear(),
          financialStartDate.getMonth() + 8,
          30
        );
        break;
      case MonthFilterType.Q4:
        startDate = new Date(
          financialStartDate.getFullYear(),
          financialStartDate.getMonth() + 9,
          30
        ); // October 1
        endDate = financialEndDate;
        break;
      default:
        throw new Error('Invalid rangeType provided.');
    }
    return { startDate, endDate };
  }

  static formatTime(time: string): string {
    let [hours, minutes] = time.split(':').map(Number);
    let amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes.toString().padStart(2, '0')} ${amPm}`;
  }
}
