import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
})
export class UserDetailsComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}
  userForm: FormGroup;

  ngOnInit() {
    this.initiateForm();
  }

  closeModal() {
    this.activeModal.close();
  }

  initiateForm() {
    this.userForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      number: new FormControl('', Validators.required),
    });
  }

  saveDetails() {
    Object.values(this.userForm.controls).forEach((control) => {
      control.markAsTouched();
    });
    if (this.userForm.valid) {
      this.activeModal.close(this.userForm.value);
    }
  }
}
