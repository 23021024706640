import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BYPASS_INJECTION } from 'src/app/core/interceptors/common.interceptor';
import {
  ApiPaginationResponse,
  ApiResponse,
  HkResponse,
} from 'src/app/core/models/common.models';
import { BaseService } from 'src/app/core/services/base.service';
import { InspectionData } from '../../ppm/models/ppm.model';

@Injectable({
  providedIn: 'root',
})
export class ChecklistService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  createChecklist(payload) {
    return this.http.post<any>(this.cixUrl + `/checklist`, payload);
  }

  getAllChecklist(payload) {
    return this.http.post<ApiPaginationResponse<any[]>>(
      this.cixUrl + `/checklist/search`,
      payload
    );
  }

  getAllinspectionsList(payload) {
    return this.http.post<ApiPaginationResponse<any[]>>(
      this.cixUrl + `/inspection/search`,
      payload
    );
  }

  getChecklistById(id) {
    return this.http.get<any>(this.cixUrl + `/checklist/` + id);
  }

  deleteCheckList(id): Observable<any> {
    return this.http.delete<any>(`${this.cixUrl}/checklist/${id}`, {
      context: new HttpContext().set(BYPASS_INJECTION, true),
    });
  }

  updateCheckList(payload, id) {
    return this.http.put<any>(`${this.cixUrl}/checklist/${id}`, payload);
  }

  patchChecklistStatus(id, checklistStatus, initiatedById) {
    let params = new HttpParams();
    params = params.set('checklistStatus', checklistStatus);
    params = params.set('initiatedById', initiatedById);
    return this.http.patch<any>(`${this.cixUrl}/checklist/${id}`, {
      params: params,
    });
  }


  generateUrl(payload) {
    return this.http.post<InspectionData>(this.cixUrl + `/cix-instance`, payload);
  }

  getAllInspections(checklistId, companyId, userId) {
    let params = new HttpParams();
    params = params.set('checklistId', checklistId);
    params = params.set('companyId', companyId);
    params = params.set('userId', userId);
    return this.http.get<any>(`${this.cixUrl}/cix-instance`, {
      params: params,
    });
  }

  getCIXInstance(checklistId, identifier) {
    return this.http.get<any>(
      this.cixUrl + `/cix-instance/` + checklistId + '/' + identifier
    );
  }

  postInspection(payload) {
    return this.http.post<any>(
      this.cixUrl +
        `/cix-instance/${payload.checklistId}/${payload.identifier}`,
      payload
    );
  }

  getInspectionById(id) {
    return this.http.get<any>(this.cixUrl + `/inspection/` + id);
  }

  changeStatus(payload, id) {
    return this.http.patch<any>(
      `${this.cixUrl}/inspection/${id}/status`,
      payload
    );
  }

  postChecklistAccess(payload, checklistId) {
    return this.http.post<any>(
      this.cixUrl + `/cix-access-rule/${checklistId}/checklist`,
      payload
    );
  }
  postChecklistAccess2(payload, checklistId) {
    return this.http.post<any>(
      this.cixUrl + `/cix-access-rule/${checklistId}/inspection`,
      payload
    );
  }

  patchChecklistAccess(payload, checklistId) {
    return this.http.patch<any>(
      this.cixUrl + `/cix-access-rule/${checklistId}/checklist`,
      payload
    );
  }
  patchChecklistAccess2(payload, checklistId) {
    return this.http.patch<any>(
      this.cixUrl + `/cix-access-rule/${checklistId}/inspection`,
      payload
    );
  }

  deleteChecklistAccess(ruleId, checklistId) {
    return this.http.delete<any>(
      this.cixUrl + `/cix-access-rule/${checklistId}/checklist/${ruleId}`,
      {
        context: new HttpContext().set(BYPASS_INJECTION, true),
      }
    );
  }

  deleteChecklistAccess2(ruleId, checklistId) {
    return this.http.delete<any>(
      this.cixUrl + `/cix-access-rule/${checklistId}/inspection/${ruleId}`,
      {
        context: new HttpContext().set(BYPASS_INJECTION, true),
      }
    );
  }
}
