<div class="loading-overlay" *ngIf="loading">
  <div class="loading"></div>
</div>
<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Upload or Capture Image</h4>
    <button (click)="activeModal.dismiss('Cross click')" type="button" class="btn-close" data-bs-dismiss="modal"
      aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <canvas #canvas [hidden]="!showCanvas" style="border: 1px solid #000; margin-bottom: 15px"></canvas>

    <div *ngIf="hideButtons">
      <div class="upload-box text-center mb-3">
        <input type="file" id="fileInput" (change)="onImageUpload($event)" class="d-none" accept="image/*" />
        <label for="fileInput" class="upload-label">
          <div class="upload-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
              class="icon icon-tabler icons-tabler-filled icon-tabler-camera">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M15 3a2 2 0 0 1 1.995 1.85l.005 .15a1 1 0 0 0 .883 .993l.117 .007h1a3 3 0 0 1 2.995 2.824l.005 .176v9a3 3 0 0 1 -2.824 2.995l-.176 .005h-14a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-9a3 3 0 0 1 2.824 -2.995l.176 -.005h1a1 1 0 0 0 1 -1a2 2 0 0 1 1.85 -1.995l.15 -.005h6zm-3 7a3 3 0 0 0 -2.985 2.698l-.011 .152l-.004 .15l.004 .15a3 3 0 1 0 2.996 -3.15z" />
            </svg>
          </div>
          <p class="upload-text">Select file</p>
        </label>
      </div>

      <div class="text-center text-muted">or</div>

      <!-- Open Camera Button -->
      <div class="text-center mt-3">
        <button class="btn btn-light btn-lg camera-btn" (click)="openCamera()">Open Camera & Take Photo</button>
      </div>
    </div>


    <!-- Webcam Stream -->
    <div *ngIf="showWebcam" class="webcam-container text-center">
      <webcam [height]="240" [width]="400" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
        [switchCamera]="nextWebcamObservable"></webcam>
      <button (click)="triggerSnapshot()" class="btn btn-primary mt-3">Capture Photo</button>
    </div>
  </div>



  <div class="modal-footer">
    <button class="btn btn-success" (click)="saveImage()" [disabled]="!showCanvas">
      Save Image
    </button>
  </div>
</div>