<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Add User Details</h5>
    <button
      (click)="closeModal()"
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="userForm">
      <div class="mb-3">
        <label class="form-label required">Name</label>
        <input
          type="text"
          class="form-control"
          formControlName="name"
          placeholder="Enter name"
        />
        <div
          *ngIf="
            userForm.get('name')?.invalid &&
            (userForm.get('name')?.dirty || userForm.get('name')?.touched)
          "
        >
          <div
            class="invalid-feedback"
            *ngIf="userForm.get('name')?.errors?.['required']"
          >
            *Name required.
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label required">Email</label>
        <input
          type="email"
          class="form-control"
          formControlName="email"
          placeholder="Enter email id"
        />
        <div
          *ngIf="
            userForm.get('email')?.invalid &&
            (userForm.get('email')?.dirty || userForm.get('email')?.touched)
          "
        >
          <div
            class="invalid-feedback"
            *ngIf="userForm.get('email')?.errors?.['required']"
          >
            *Email id required.
          </div>
          <div
            class="invalid-feedback"
            *ngIf="userForm.get('email')?.errors?.['email']"
          >
            *Invalid email id.
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label required">Phone Number</label>
        <input
          type="number"
          class="form-control"
          formControlName="number"
          placeholder="Enter Phonenumber"
        />
        <div
          *ngIf="
            userForm.get('number')?.invalid &&
            (userForm.get('number')?.dirty || userForm.get('number')?.touched)
          "
        >
          <div
            class="invalid-feedback"
            *ngIf="userForm.get('number')?.errors?.['required']"
          >
            *Phonenumber required.
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      (click)="closeModal()"
      type="button"
      class="btn me-auto"
      data-bs-dismiss="modal"
    >
      Close
    </button>
    <button
      (click)="saveDetails()"
      type="submit"
      class="btn btn-primary"
      data-bs-dismiss="modal"
    >
      Create
    </button>
  </div>
</div>
