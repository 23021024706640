<aside class="navbar navbar-vertical navbar-expand-lg navbar-dark">
  <div class="container-fluid">
    <div class="fullpad">
      <div class="row">
        <div class="col-3">
          <a>
            <img src="assets/images/usermanagement/thumb.svg" width="28" height="28" class="mx-auto d-block" />
          </a>
        </div>
        <div class="col-3">
          <div class="navbar-nav flex-row">
            <div class="nav-item dropdown dropend">
              <a class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                <ngx-avatar [src]="userImage" [name]="displayName" size="22" [textSizeRatio]="2"
                  [initialsSize]="2"></ngx-avatar>
              </a>
              <div class="left0 dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <div class="mt-1 small p-2">
                  <strong>{{ displayName }}</strong> <br />
                  <span class="small text-muted">{{ designation }}</span>
                </div>
                <a class="dropdown-item" href="{{ oldSiteURL }}/v1/#/users/profile/{{
                    currentUser.id
                  }}">Profile</a>
                <a (click)="logout()" class="dropdown-item">Logout</a>
                <!-- <a *ngIf="!isV3User()" (click)="logout()" class="dropdown-item"
                                    href="{{ oldSiteURL }}/v1/#/login">Logout</a> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <a><i class="ti ti-bell mt-2 white_color mx-auto d-block icon-font"></i></a>
        </div>
        <div class="col-3">
          <a (click)="hideNav()"><i class="ti ti-menu-2 mt-2 white_color no-small icon-font"></i></a>
          <button class="navbar-toggler" data-bs-toggle="collapse" (click)="navSelected = !navSelected"
            data-bs-target="#navbar-menu" type="button" [attr.aria-expanded]="navSelected">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="collapse navbar-collapse" [ngClass]="{ show: navSelected }" id="navbar-menu">
      <div class="ps-3 pe-1 small" *ngIf="canSwitchProfile()">
        <div class="nav-item dropdown">
          <a class="nav-link dropdown-toggle align-items-center" data-bs-toggle="dropdown" data-bs-auto-close="outside"
            role="button" aria-expanded="false">
            <span
              class="nav-link-icon d-md-none d-lg-inline-block pt-1"><!-- Download SVG icon from http://tabler-icons.io/i/star -->
              <ngx-avatar [src]="userImage" [name]="getActiveSite()?.entityName" size="14" [textSizeRatio]="2"
                [initialsSize]="1"></ngx-avatar>
            </span>
            <span class="nav-link-title wrap-word">
              {{ getActiveSite()?.entityName }}
            </span>
          </a>
          <div class="dropdown-menu" style="max-height: 100px; overflow: auto">
            <a class="dropdown-item small p-2 cursor-pointer" style="background: #2d384f"
              *ngFor="let site of getProfileListForUser()" (click)="switchProfile(site.id)">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block pt-1"><!-- Download SVG icon from http://tabler-icons.io/i/star -->
                <ngx-avatar [src]="userImage" [name]="site?.entityName" size="14" [textSizeRatio]="2"
                  [initialsSize]="1"></ngx-avatar>
              </span>
              <span class="wrap-word">{{ site?.entityName }}</span>
            </a>
          </div>
        </div>
      </div>
      <ul class="navbar-nav pt-lg-3">
        <li class="nav-item">
          <a [ngClass]="{ 'active ': activeRoute('/home') }" (click)="closeNav()" class="nav-link"
            routerLink="/home"><span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-home icon-font"></i>
            </span>
            <span class="nav-link-title"> Home </span>
          </a>
        </li>

        <li class="nav-item">
          <a (click)="closeNav()" [ngClass]="{ active: activeRoute('announcements/list') }" class="nav-link"
            routerLink="/announcements/list"><span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-speakerphone icon-font"></i>
            </span>
            <span class="nav-link-title"> Announcements </span>
          </a>
        </li>
        <li *ngIf="permissionEvaluator.isUserHaveSchedulerPermission()" class="nav-item">
          <a class="nav-link" routerLink="/scheduler/my-calendar"><span
              class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-calendar-event icon-font"></i>
            </span>
            <span class="nav-link-title"> Calendar </span>
          </a>
        </li>

        <li class="nav-item" *ngIf="permissionEvaluator.isUserHaveManageDutyLogPermission()">
          <a href="#" (click)="openExternalLink()" class="nav-link">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <!-- <i class="ti ti-home icon-font"></i> -->
              <i class="ti ti-books icon-font"></i>
            </span>
            <span class="nav-link-title">Logs</span>
          </a>
        </li>


        <li *ngIf="permissionEvaluator.isUserHaveSchedulerPermission()" class="nav-item">
          <a class="nav-link" routerLink="/scheduler/my-calendar"><span
              class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-calendar-event icon-font"></i>
            </span> <span class="nav-link-title"> Calendar </span> </a>
        </li>

        <li *ngIf="permissionEvaluator.isUsetHaveGatePassPermission()" class="nav-item">
          <a (click)="closeNav()" [ngClass]="{'active': activeRoute('gate-pass')}" class="nav-link"
            routerLink="/gate-pass"><span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-receipt icon-font"></i>
            </span> <span class="nav-link-title"> Gate Pass </span> </a>
        </li>

        <li *ngIf="permissionEvaluator.isUserHaveMomPermission()" class="nav-item">
          <a class="nav-link" routerLink="/meetings" [ngClass]="{'active': activeRoute('meetings')}"><span
              class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-calendar-user icon-font"></i>
            </span> <span class="nav-link-title"> MOM </span> </a>
        </li>
        <!-- <li *ngIf="permissionEvaluator.isUserHaveMomPermission()" class="nav-item">
          <a class="nav-link" routerLink="/meetings" [ngClass]="{ active: activeRoute('meetings') }"><span
              class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-calendar-user icon-font"></i>
            </span>
            <span class="nav-link-title"> MOM </span>
          </a>
        </li> -->

        <li *ngIf="permissionEvaluator.isUserHaveTaskPermission()" class="nav-item">
          <a (click)="closeNav()" [ngClass]="{ active: activeRoute('task') }" class="nav-link" routerLink="/task"><span
              class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-clipboard-text icon-font"></i>
            </span>
            <span class="nav-link-title"> Tasks </span>
          </a>
        </li>



        <li *ngIf="permissionEvaluator.isUserHaveDocumentPermission()" class="nav-item">
          <a (click)="closeNav()" [ngClass]="{ active: activeRoute('documents') }" class="nav-link"
            routerLink="/documents"><span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-file-description icon-font"></i>
            </span>
            <span class="nav-link-title"> Documents </span>
          </a>
        </li>

        <li *ngIf="permissionEvaluator.isUserServiceHKPermission()  && !permissionEvaluator.isRequestManager()" class="nav-item">
          <a class="nav-link" routerLink="/work-order" [ngClass]="{'active': activeRoute('work-order')}"><span
              class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-settings-dollar icon-font"></i>
            </span> <span class="nav-link-title">Work order </span> </a>
        </li>

        <li *ngIf="permissionEvaluator.isUserServiceHKPermission() && permissionEvaluator.isRequestManager()" class="nav-item">
          <a 
            class="nav-link dropdown-toggle" 
            href="#navbar-extra" 
            [ngClass]="{'active': activeRoute('/work-order') || activeRoute('/work-order/dashboard')}"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-settings-dollar icon-font"></i>
            </span>
            <span class="nav-link-title"> Work order </span>
          </a>

          <div class="dropdown-menu">
            <div class="dropdown-menu-columns">
              <div class="dropdown-menu-column">
                <a 
                  class="dropdown-item" 
                  (click)="closeNav()" 
                  [ngClass]="{'active': activeRoute('/work-order/dashboard')}"
                  routerLink="/work-order/dashboard"
                >
                  Dashboard
                </a>
                <a 
                  class="dropdown-item" 
                  (click)="closeNav()" 
                  [ngClass]="{'active': activeRoute('/work-order') && !activeRoute('/work-order/dashboard')}"
                  routerLink="/work-order"
                >
                  All Work orders
                </a>
                
                
               
              </div>
            </div>
          </div>
        </li>





        <!-- <li *ngIf="permissionEvaluator.isUserHaveAuditPermission()" class="nav-item">
          <a (click)="closeNav()" [ngClass]="{'active': activeRoute('audit')}" class="nav-link"
            routerLink="/audit/list"><span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-checkup-list icon-font"></i>
            </span> <span class="nav-link-title"> Audits </span>
          </a>
        </li> -->

        <li class="nav-item">
          <a (click)="closeNav()" (click)="closeNav()" routerLink="/dashboard/guest"
            [ngClass]="{'active': (activeRoute('dashboard/guest'))}" class="nav-link">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-brand-messenger icon-font"></i>
            </span>
            <span class="nav-link-title"> Communications</span>
          </a>
        </li>

        <li *ngIf="permissionEvaluator.isUserHavePettyCashPermission()" class="nav-item">
          <a class="nav-link dropdown-toggle" href="#navbar-extra" [ngClass]="{'active': activeRoute('accounts')}"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false"> <span
              class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-cash fs-2"></i>

            </span> <span class="nav-link-title"> Accounts </span> </a>
          <div class="dropdown-menu" [ngClass]="{'show': activeRoute('petty-cash')}">
            <div class="dropdown-menu-columns">
              <div class="dropdown-menu-column">
                <a class="dropdown-item" (click)="closeNav()"
                  [ngClass]="{'active': activeRoute('/accounts/petty-cash')}" routerLink="/accounts/petty-cash">
                  Petty Cash </a>
                <!-- <a class="nav-link dropdown-toggle" href="#navbar-extra"
                  [ngClass]="{'active': activeRoute('/checklist')}" data-bs-toggle="dropdown" data-bs-auto-close="false"
                  role="button" aria-expanded="false"> <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <i class="ti ti-list-check fs-2"></i>
                  </span> <span class="nav-link-title"> Checklist </span> </a>
                <div class="dropdown-menu" [ngClass]="{'show': activeRoute('/checklist')}">
                  <div class="dropdown-menu-columns">
                    <div class="dropdown-menu-column">
                      <a class="dropdown-item" (click)="closeNav()"
                        [ngClass]="{'active': activeRoute('/checklist/templates')}" routerLink="/checklist/templates">
                        Templates </a>
                    </div>

                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link dropdown-toggle" href="#navbar-extra" [ngClass]="{ active: activeRoute('/checklist') }"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-list-check fs-2"></i>
            </span>
            <span class="nav-link-title"> Quality & Assurance </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{ show: activeRoute('/checklist') }">
            <div class="dropdown-menu-columns">
              <div class="dropdown-menu-column">
                <a class="dropdown-item" (click)="closeNav()"
                  [ngClass]="{ active: activeRoute('/checklist/templates') }" routerLink="/checklist/templates">
                  Checklist
                </a>
                <a class="dropdown-item" (click)="closeNav()"
                  [ngClass]="{ active: activeRoute('/checklist/inspections') }" routerLink="/checklist/inspections">
                  Inspections
                </a>
                <a (click)="closeNav()" [ngClass]="{ active: activeRoute('/audit/list') }" class="dropdown-item"
                  routerLink="/audit/list">
                  <span class="nav-link-title"> Audits </span>
                </a>


                <a *ngIf="permissionEvaluator.isUserHaveIncidentPermission()" class="dropdown-item"
                  routerLink="/log-management/incidents"
                  [ngClass]="{ active: activeRoute('/log-management/incidents') }">
                  <span class="nav-link-title">Incidents </span>
                </a>
              </div>
            </div>
          </div>
        </li>

        <li *ngIf="permissionEvaluator.isUserHaveBudgetPermission()" class="nav-item">
          <a class="nav-link dropdown-toggle" href="#navbar-extra" [ngClass]="{'active': activeRoute('goals')}"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false"> <span
              class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-report-money fs-2"></i>
            </span> <span class="nav-link-title"> Financials </span> </a>
          <div class="dropdown-menu" [ngClass]="{'show': activeRoute('budget')}">
            <div class="dropdown-menu-columns">
              <div class="dropdown-menu-column">
                <a class="dropdown-item" (click)="closeNav()" [ngClass]="{'active': activeRoute('/budget/dashboard')}"
                  routerLink="/budget/dashboard">
                  Dashboard </a>
                <a class="dropdown-item" (click)="closeNav()"
                  [ngClass]="{'active': activeRoute('/budget') && !activeRoute('/budget/') && !activeRoute('/budget/corporate-expenses') && !activeRoute('/budget/proposals-list')}"
                  routerLink="/budget">Budget</a>
                <a class="dropdown-item" (click)="closeNav()" *ngIf="permissionEvaluator.isCorporate()"
                  [ngClass]="{'active': activeRoute('/budget/corporate-expenses')}"
                  routerLink="/budget/corporate-expenses">Corporate Expenses</a>
                <div class="dropend">
                  <a class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
                    data-bs-auto-close="false" role="button" aria-expanded="false"
                    [ngClass]="{'show': activeRoute('budget/p&l-summary')}">
                    P&L </a>
                  <div class="dropdown-menu" [ngClass]="{' show': activeRoute('budget/p&l')}">
                    <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/budget/p&l-summary')}"
                      class="dropdown-item" routerLink="/budget/p&l-summary">
                      P&L Statements</a>
                    <a (click)="closeNav()" [ngClass]="{'active': activeRoute('/budget/p&l-dashboard')}"
                      class="dropdown-item" routerLink="/budget/p&l-dashboard">
                      P&L Dashboard</a>

                  </div>
                </div>
              </div>
              <!-- <a class="dropdown-item" (click)="closeNav()" *ngIf="permissionEvaluator.isCorporate()"
        <li class="nav-item">
          <a
            class="nav-link dropdown-toggle"
            href="#navbar-extra"
            [ngClass]="{ active: activeRoute('/checklist') }"
            data-bs-toggle="dropdown"
            data-bs-auto-close="false"
            role="button"
            aria-expanded="false"
          >
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-list-check fs-2"></i>
            </span>
            <span class="nav-link-title"> CIX </span>
          </a>
          <div
            class="dropdown-menu"
            [ngClass]="{ show: activeRoute('/checklist') }"
          >
            <div class="dropdown-menu-columns">
              <div class="dropdown-menu-column">
                <a
                  class="dropdown-item"
                  (click)="closeNav()"
                  [ngClass]="{ active: activeRoute('/checklist/templates') }"
                  routerLink="/checklist/templates"
                >
                  Checklist
                </a>
                <a
                  class="dropdown-item"
                  (click)="closeNav()"
                  [ngClass]="{ active: activeRoute('/checklist/inspections') }"
                  routerLink="/checklist/inspections"
                >
                  Inspections
                </a>
              </div>
            </div>
          </div>
        </li>

        <li
          *ngIf="permissionEvaluator.isUserHaveBudgetPermission()"
          class="nav-item"
        >
          <a
            class="nav-link dropdown-toggle"
            href="#navbar-extra"
            [ngClass]="{ active: activeRoute('goals') }"
            data-bs-toggle="dropdown"
            data-bs-auto-close="false"
            role="button"
            aria-expanded="false"
          >
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-report-money fs-2"></i>
            </span>
            <span class="nav-link-title"> Financials </span>
          </a>
          <div
            class="dropdown-menu"
            [ngClass]="{ show: activeRoute('budget') }"
          >
            <div class="dropdown-menu-columns">
              <div class="dropdown-menu-column">
                <a
                  class="dropdown-item"
                  (click)="closeNav()"
                  [ngClass]="{ active: activeRoute('/budget/dashboard') }"
                  routerLink="/budget/dashboard"
                >
                  Dashboard
                </a>
                <a
                  class="dropdown-item"
                  (click)="closeNav()"
                  [ngClass]="{
                    active:
                      activeRoute('/budget') &&
                      !activeRoute('/budget/') &&
                      !activeRoute('/budget/corporate-expenses') &&
                      !activeRoute('/budget/proposals-list')
                  }"
                  routerLink="/budget"
                  >Budget</a
                >
                <a
                  class="dropdown-item"
                  (click)="closeNav()"
                  *ngIf="permissionEvaluator.isCorporate()"
                  [ngClass]="{
                    active: activeRoute('/budget/corporate-expenses')
                  }"
                  routerLink="/budget/corporate-expenses"
                  >Corporate Expenses</a
                >
                <div class="dropend">
                  <a
                    class="dropdown-item dropdown-toggle"
                    href="#sidebar-cards"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="false"
                    role="button"
                    aria-expanded="false"
                    [ngClass]="{ show: activeRoute('budget/p&l-summary') }"
                  >
                    P&L
                  </a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{ ' show': activeRoute('budget/p&l') }"
                  >
                    <a
                      (click)="closeNav()"
                      [ngClass]="{ active: activeRoute('/budget/p&l-summary') }"
                      class="dropdown-item"
                      routerLink="/budget/p&l-summary"
                    >
                      P&L Statements</a
                    >
                    <a
                      (click)="closeNav()"
                      [ngClass]="{
                        active: activeRoute('/budget/p&l-dashboard')
                      }"
                      class="dropdown-item"
                      routerLink="/budget/p&l-dashboard"
                    >
                      P&L Dashboard</a
                    >
                  </div>
                </div>
              </div>
             <a class="dropdown-item" (click)="closeNav()" *ngIf="permissionEvaluator.isCorporate()"
                                    [ngClass]="{'active': activeRoute('/budget/p&l') && !activeRoute('/budget/p&l-dashboard')}"
                                    routerLink="/budget/p&l">P&L Summary</a>
                                    <a class="dropdown-item" (click)="closeNav()" *ngIf="permissionEvaluator.isCorporate()"
                                    [ngClass]="{'active': activeRoute('/budget/p&l-dashboard') && !activeRoute('/budget/p&l') }"
                                    routerLink="/budget/p&l-dashboard">P&L Dashboard</a> -->
              <a class="dropdown-item" (click)="closeNav()" *ngIf="permissionEvaluator.isCorporate()" [ngClass]="{
                  active:
                    activeRoute('/budget/proposals-list') ||
                    activeRoute('/budget/capex-budget-approval-advanced')
                }" routerLink="/budget/proposals-list">Approvals
              </a>
            </div>
          </div>
          <!-- </div> -->
        </li>

        <li *ngIf="permissionEvaluator.isUserHaveLogPermission()" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#navbar-help" [ngClass]="{
              'active show':
                activeRoute('log-management') &&
                !activeRoute('log-management/incidents') &&
                !activeRoute('log-management/hr-connect')
            }" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" [attr.aria-expanded]="
              activeRoute('log-management') &&
              !activeRoute('log-management/incidents') &&
              !activeRoute('log-management/hr-connect')
            ">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-notebook icon-font"></i></span>
            <span class="nav-link-title"> Department Logs </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{
              show:
                activeRoute('log-management') &&
                !activeRoute('log-management/incidents') &&
                !activeRoute('log-management/hr-connect')
            }">
            <!-- <a *ngIf="permissionEvaluator.isUserHaveManageDutyLogPermission()" class="dropdown-item"
                            (click)="closeNav()" routerLink="/log-management/duty-log"
                            [ngClass]="{'active': activeRoute('log-management/duty-log')}">Duty Log</a> -->

            <a *ngIf="permissionEvaluator.isUserHaveManageReadingPermission()" class="dropdown-item"
              (click)="closeNav()" routerLink="/log-management/dashboard"
              [ngClass]="{ active: activeRoute('log-management/dashboard') }">Readings</a>
            <a *ngIf="permissionEvaluator.isUserHaveManageChemicalsPermission()" class="dropdown-item"
              (click)="closeNav()" routerLink="/log-management/chemicals"
              [ngClass]="{ active: activeRoute('log-management/chemicals') }">Chemicals</a>
            <a *ngIf="
                permissionEvaluator?.isUserHaveManageTransactionsPermission()
              " class="dropdown-item" (click)="closeNav()" routerLink="/log-management/home"
              [ngClass]="{ active: activeRoute('log-management/home') }">Transactions</a>
            <a *ngIf="permissionEvaluator.isUserHaveManageActivityPermission()" class="dropdown-item"
              (click)="closeNav()" routerLink="/log-management/activities"
              [ngClass]="{ active: activeRoute('log-management/activities') }">Activities</a>
            <a *ngIf="
                permissionEvaluator.isUserHaveManageContractorsPermission()
              " class="dropdown-item" routerLink="/log-management/contractors-log" [ngClass]="{
                active: activeRoute('log-management/contractors-log')
              }">Labours/Contractors</a>

            <a *ngIf="
                permissionEvaluator.isUserHaveManageLostAndFoundPermission()
              " class="dropdown-item" routerLink="/lost-and-found"
              [ngClass]="{ active: activeRoute('lost-and-found') }">
              Lost & Found
            </a>

            <a class="dropdown-item" *ngIf="permissionEvaluator.isUserHaveManageLaundryPermission()"
              routerLink="log-management/laundry"
              [ngClass]="{'active': activeRoute('log-management/laundry')}">Laundry</a>
            <a *ngIf="permissionEvaluator.isUserHaveManageLuggagePermission()" class="dropdown-item"
              routerLink="/log-management/luggages" [ngClass]="{'active': activeRoute('log-management/luggages')}">Left
              Luggages </a>

            <a class="dropdown-item" routerLink="/log-management/guest-laundry"
              [ngClass]="{'active': activeRoute('log-management/guest-laundry')}">Guest Laundry </a>

            <a class="dropdown-item" *ngIf="permissionEvaluator?.isUserHaveManageWorkLogPermission()"
              routerLink="/log-management/work-log" [ngClass]="{'active': activeRoute('log-management/work-log')}"> Work
              Log </a>


            <a *ngIf="permissionEvaluator.isUserHaveManageLuggagePermission() && permissionEvaluator.isUserHaveManageFoPermission()"
              class="dropdown-item" routerLink="/budget/p&l-monthly-closure"
              [ngClass]="{'active': activeRoute('budget/p&l-monthly-closure')}">Monthly P&L Closure </a>

          </div>
        </li>
        <li class="nav-item" *ngIf="permissionEvaluator.isUserHaveEmployeeConnectPermission()">
          <a (click)="closeNav()" [ngClass]="{'active': activeRoute('hr-connect')}" class="nav-link"
            routerLink="/log-management/hr-connect"><span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-users icon-font"></i>
            </span> <span class="nav-link-title"> Employee Connect </span> </a>
        </li>
        <!-- <li  class="nav-item">
            <a
              *ngIf="permissionEvaluator.isUserHaveManageLuggagePermission()"
              class="dropdown-item"
              routerLink="/log-management/luggages"
              [ngClass]="{ active: activeRoute('log-management/luggages') }"
              >Left Luggages
            </a>

            <a
              class="dropdown-item"
              *ngIf="permissionEvaluator?.isUserHaveManageWorkLogPermission()"
              routerLink="/log-management/work-log"
              [ngClass]="{ active: activeRoute('log-management/work-log') }"
            >
              Work Log
            </a>

            <a
              *ngIf="
                permissionEvaluator.isUserHaveManageLuggagePermission() &&
                permissionEvaluator.isUserHaveManageFoPermission()
              "
              class="dropdown-item"
              routerLink="/budget/p&l-monthly-closure"
              [ngClass]="{ active: activeRoute('budget/p&l-monthly-closure') }"
              >Monthly P&L Closure
            </a>
          </div>
        </li>
        <li
          class="nav-item"
          *ngIf="permissionEvaluator.isUserHaveEmployeeConnectPermission()"
        >
          <a
            (click)="closeNav()"
            [ngClass]="{ active: activeRoute('hr-connect') }"
            class="nav-link"
            routerLink="/log-management/hr-connect"
            ><span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-users icon-font"></i>
            </span>
            <span class="nav-link-title"> Employee Connect </span>
          </a>
        </li>
         <li  class="nav-item">
                    <a (click)="closeNav()" [ngClass]="{'active': activeRoute('duty-handover-log')}" class="nav-link"
                        routerLink="/log-management/duty-handover-log"><span
                            class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-file-description icon-font"></i>
                        </span> <span class="nav-link-title"> Duty Handover Log </span> </a>
                </li> -->
        <li class="nav-item" *ngIf="permissionEvaluator.isUserHaveGuestEntryPermission()">
          <a (click)="closeNav()" [ngClass]="{'active': activeRoute('room-calender')}" class="nav-link"
            routerLink="gms/room-calender"><span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-calendar-user icon-font"></i>
            </span> <span class="nav-link-title"> Rooms Calender </span> </a>
        </li>
        <!-- <li *ngIf="permissionEvaluator.isUserHaveGuestEntryPermission()"
                    [ngClass]="{'active show': (activeRoute('gms'))}" class="nav-item dropdown"> <a
                        class="nav-link dropdown-toggle" href="#navbar-base" data-bs-toggle="dropdown"
                        data-bs-auto-close="false" role="button" aria-expanded="false"> <span
                            class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-user-plus icon-font"></i>
                        </span> <span class="nav-link-title"> Guest Management </span> </a>
                    <div class="dropdown-menu" [ngClass]="{'show': (activeRoute('gms'))}">
                        <div class="dropdown-menu-columns">
                            <div class="dropdown-menu-column">
                                <a class="dropdown-item" (click)="closeNav()"
                                    [ngClass]="{'active': (activeRoute('room-reservation'))}"
                                    href="{{oldSiteURL}}/v1/#/guest-entry/view"> Room Reservation </a>
                                <a class="dropdown-item" (click)="closeNav()"
                                    [ngClass]="{'active': (activeRoute('spa-reservations'))}"
                                    href="{{ oldSiteURL }}/v2/#/spa-reservations"> Spa
                                    Reservations</a>
                                <a class="dropdown-item" (click)="closeNav()"
                                    [ngClass]="{'active': (activeRoute('guest-entry/online-check-in'))}"
                                    href="{{ oldSiteURL }}/v1/#/guest-entry/online-check-in"> Online
                                    Check In</a>
                                <a class="dropdown-item" (click)="closeNav()"
                                    [ngClass]="{'active': (activeRoute('guest-entry/checkout-feedback'))}"
                                    href="{{ oldSiteURL }}/v1/#/guest-entry/checkout-feedback">
                                    Check-out Survey</a>

                                <a class="dropdown-item" (click)="closeNav()" routerLink="/dashboard/guest"
                                    [ngClass]="{'active': (activeRoute('dashboard/guest'))}">
                                    Communications</a>
                            </div>
                        </div>
                    </div>
                </li> -->

        <li *ngIf="permissionEvaluator.isUserHaveGuestEntryPermission()"
          [ngClass]="{ 'active show': activeRoute('guest-entry') }" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#navbar-base" data-bs-toggle="dropdown" data-bs-auto-close="false"
            role="button" aria-expanded="false">
            <span
              class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/package -->
              <i class="ti ti-user-plus icon-font"></i>
            </span>
            <span class="nav-link-title"> Guest Management </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{ show: activeRoute('guest-entry') }">
            <div class="dropdown-menu-columns">
              <div class="dropdown-menu-column">
                <a class="dropdown-item" (click)="closeNav()" [ngClass]="{ active: activeRoute('guest-entry/view') }"
                  href="{{ oldSiteURL }}/v1/#/guest-entry/view">
                  Room Reservation
                </a>
                <a class="dropdown-item" (click)="closeNav()" [ngClass]="{ active: activeRoute('spa-reservations') }"
                  href="{{ oldSiteURL }}/v2/#/spa-reservations">
                  Spa Reservations</a>
                <a class="dropdown-item" (click)="closeNav()" [ngClass]="{
                    active: activeRoute('guest-entry/online-check-in')
                  }" href="{{ oldSiteURL }}/v1/#/guest-entry/online-check-in">
                  Online Check In</a>
                <a class="dropdown-item" (click)="closeNav()" [ngClass]="{
                    active: activeRoute('guest-entry/checkout-feedback')
                  }" href="{{ oldSiteURL }}/v1/#/guest-entry/checkout-feedback">
                  Check-out Survey</a>
              </div>
            </div>
          </div>
        </li>

        <li *ngIf="permissionEvaluator.isUserHaveFeedbackPermission()" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#navbar-base" [ngClass]="{
              'active show': activeRoute('feedback') || activeRoute('reviews')
            }" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" [attr.aria-expanded]="
              activeRoute('feedback') || activeRoute('reviews')
            ">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-messages icon-font"></i>
            </span>
            <span class="nav-link-title"> Guest Experience </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{
              show:
                activeRoute('feedback') ||
                activeRoute('reviews') ||
                activeRoute('guest-experience') ||
                activeRoute('issues') ||
                activeRoute('reports')
            }">
            <div class="dropdown-menu-columns">
              <div class="dropdown-menu-column">
                <a class="dropdown-item" (click)="closeNav()" routerLink="/dashboard/guest-experience" [ngClass]="{
                    active: activeRoute('dashboard/guest-experience')
                  }">
                  Dashboard</a>
                <a class="dropdown-item" (click)="closeNav()" routerLink="/feedback" [ngClass]="{
                    active:
                      activeRoute('feedback') && !activeRoute('feedback/issues')
                  }">
                  Guest Survey</a>
                <a class="dropdown-item" (click)="closeNav()" routerLink="/feedback/issues"
                  [ngClass]="{ active: activeRoute('feedback/issues') }">
                  Issues</a>

                <a *ngIf="permissionEvaluator.isEntityHaveReviewPermission()" class="dropdown-item dropdown-toggle"
                  href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                  aria-expanded="false">
                  Reviews
                </a>

                <div class="dropdown-menu" [ngClass]="{
                    show:
                      activeRoute('all-reviews') ||
                      activeRoute('static-reviews')
                  }">
                  <div class="dropdown-menu-columns">
                    <div class="dropdown-menu-column">
                      <a class="dropdown-item" (click)="closeNav()" routerLink="/reviews/all-reviews"
                        [ngClass]="{ active: activeRoute('all-reviews') }">
                        All Reviews</a>
                      <a class="dropdown-item" (click)="closeNav()" routerLink="/reviews/static-reviews"
                        [ngClass]="{ active: activeRoute('static-reviews') }">
                        ORM Score</a>
                    </div>
                  </div>
                </div>

                <a *ngIf="
                    permissionEvaluator.isUserHaveFeedbackReportPermission()
                  " class="dropdown-item" (click)="closeNav()" [ngClass]="{ active: activeRoute('reports') }"
                  routerLink="/reports">
                  Reports
                </a>
              </div>
            </div>
          </div>
        </li>

        <!-- <li *ngIf="permissionEvaluator.isUserServiceHKPermission()" class="nav-item dropdown">
                    <a [ngClass]="{'active show': (activeRoute('wo')||activeRoute('work-order'))}"
                        class="nav-link dropdown-toggle" href="#navbar-extra" data-bs-toggle="dropdown"
                        data-bs-auto-close="false" role="button" aria-expanded="false"> <span
                            class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-calendar-star icon-font"></i>

                        </span> <span class="nav-link-title"> Work order </span> </a>
                    <div class="dropdown-menu" [ngClass]="{'show': (activeRoute('wo')|| activeRoute('work-order'))}">
                        <div class="dropdown-menu-columns">
                            <div class="dropdown-menu-column">
                                <a class="dropdown-item" [ngClass]="{'active': activeRoute('wo/list')}"
                                    (click)="closeNav()" routerLink="/work-order">All Work
                                    Orders</a>
                               
                                <a class="dropdown-item" [ngClass]="{'active': activeRoute('wo/my-work')}"
                                    (click)="closeNav()" href="{{ oldSiteURL }}/#/wo/my-work"> My Work order </a>
                                <a class="dropdown-item" [ngClass]="{'active': activeRoute('wo/settings')}"
                                    (click)="closeNav()" href="{{ oldSiteURL }}/#/wo/settings/">Settings</a>
                                <a class="dropdown-item" [ngClass]="{'active': activeRoute('work-order/devices')}"
                                    (click)="closeNav()" href="{{ oldSiteURL }}/v2/#/work-order/devices"> Devices </a>

                                <a *ngIf="permissionEvaluator.isUserHaveWorkOrderReportPermission()"
                                    [ngClass]="{'active': activeRoute('reports/wo')}" (click)="closeNav()"
                                    class="dropdown-item" href="{{ oldSiteURL }}/#/reports/wo"> Reports </a>

                            </div>
                        </div>
                    </div>
                </li> -->









        <li *ngIf="permissionEvaluator.isUserHaveKraPermission()" class="nav-item">
          <a class="nav-link dropdown-toggle" href="#navbar-extra" [ngClass]="{'active': activeRoute('goals')}"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false"> <span
              class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-target-arrow icon-font"></i>
            </span> <span class="nav-link-title"> Goals </span> </a>
          <!-- <a (click)="closeNav()" [ngClass]="{'active': activeRoute('goals')}"
        <li
          *ngIf="permissionEvaluator.isUserHaveKraPermission()"
          class="nav-item"
        >
          <a
            class="nav-link dropdown-toggle"
            href="#navbar-extra"
            [ngClass]="{ active: activeRoute('goals') }"
            data-bs-toggle="dropdown"
            data-bs-auto-close="false"
            role="button"
            aria-expanded="false"
          >
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-target-arrow icon-font"></i>
            </span>
            <span class="nav-link-title"> Goals </span>
          </a>
          <a (click)="closeNav()" [ngClass]="{'active': activeRoute('goals')}"
                        class="nav-link" href="{{ oldSiteURL }}/v3/#/goals/kra-list"><span
                            class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-target-arrow icon-font"></i>

                        </span> <span class="nav-link-title"> Goals </span> </a> -->
          <div class="dropdown-menu" [ngClass]="{ show: activeRoute('goals') }">
            <div class="dropdown-menu-columns">
              <div class="dropdown-menu-column">
                <!-- <a class="dropdown-item" href="{{ oldSiteURL }}/v3/#/goals/kra-home">Dashboard </a> -->
                <!-- <a class="dropdown-item" (click)="closeNav()"
                                    [ngClass]="{'active': activeRoute('kra-list')}" routerLink="/goals/kra-list">
                                    Monthly KRA </a> -->
                <a class="dropdown-item" (click)="closeNav()" [ngClass]="{ active: activeRoute('kra-home') }"
                  routerLink="/goals/kra-home">
                  KRA-Dashboard
                </a>
              </div>
            </div>
          </div>
        </li>

        <li *ngIf="permissionEvaluator.isUserHaveHKPermission()" class="nav-item dropdown">
          <a [ngClass]="{ active: activeRoute('housekeeping') }" class="nav-link dropdown-toggle" href="#navbar-extra"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-brush icon-font"></i>
            </span>
            <span class="nav-link-title"> Housekeeping </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{ 'show active': activeRoute('housekeeping') }">
            <div class="dropdown-menu-columns">
              <div class="dropdown-menu-column">
                <a class="dropdown-item" routerLink="/ppm-cycle" [ngClass]="{ active: activeRoute('ppm') }">
                  <span class="nav-link-title">PPM For Rooms </span>
                </a>
                <a class="dropdown-item" [ngClass]="{ active: activeRoute('/work-schedule') }"
                  routerLink="/housekeeping/work-schedule">
                  Work Schedule
                </a>
                <a class="dropdown-item" [ngClass]="{ active: activeRoute('/attendance-week') }"
                  routerLink="/housekeeping/attendance-week">
                  Attendance
                </a>
                <a class="dropdown-item" [ngClass]="{ active: activeRoute('/room-status') }"
                  routerLink="/housekeeping/room-status">
                  Room Status
                </a>
                <!-- <a class="dropdown-item"
                                    href="{{ oldSiteURL }}/v3/#/lost-and-found"> Lost & Found </a> -->
                <div class="dropend">
                  <a class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
                    data-bs-auto-close="false" role="button" aria-expanded="false">
                    Reports
                  </a>
                  <div class="dropdown-menu" [ngClass]="{
                      active:
                        activeRoute('housekeeping/status-report-attendance') ||
                        activeRoute('housekeeping/status-report-room-status') ||
                        activeRoute('housekeeping/room-report') ||
                        activeRoute('housekeeping/performance-board') ||
                        activeRoute('housekeeping/guest-ra-rquest')
                    }">
                    <a (click)="closeNav()" [ngClass]="{
                        active: activeRoute('/room-status-reports')
                      }" class="dropdown-item" routerLink="/housekeeping/room-status-reports">
                      Room Status Report</a>
                    <!-- <a (click)="closeNav()"
                                            [ngClass]="{'active': activeRoute('/status-report-attendance')}"
                                            class="dropdown-item" routerLink="/housekeeping/status-report-attendance">
                                            Status
                                            Report RA </a>
                                        <a (click)="closeNav()"
                                            [ngClass]="{'active': activeRoute('/status-report-room-status')}"
                                            class="dropdown-item" routerLink="/housekeeping/status-report-room-status">
                                            Status
                                            Report Room </a> -->
                    <a (click)="closeNav()" [ngClass]="{
                        active: activeRoute('/status-report-daily-status')
                      }" class="dropdown-item" routerLink="/housekeeping/status-report-daily-status">
                      Daily Status Report
                    </a>
                    <a (click)="closeNav()" [ngClass]="{ active: activeRoute('/guest-ra-rquest') }"
                      class="dropdown-item" routerLink="/housekeeping/guest-ra-rquest">
                      Guest RA Request
                    </a>

                    <a (click)="closeNav()" [ngClass]="{
                        active: activeRoute('/status-report-attendance')
                      }" class="dropdown-item" routerLink="/housekeeping/status-report-attendance">
                      Status Report RA
                    </a>
                    <a (click)="closeNav()" [ngClass]="{
                        active: activeRoute('/status-report-room-status')
                      }" class="dropdown-item" routerLink="/housekeeping/status-report-room-status">
                      Status Report Room
                    </a>
                    <!-- <a (click)="closeNav()"
                                            [ngClass]="{'active': activeRoute('/performance-board')}"
                                            class="dropdown-item" routerLink="/housekeeping/performance-board">
                                            Performance
                                            Board </a>
                                        <a class="dropdown-item" routerLink="/house-keeping/guest-ra-rquest">
                                            Guest RA Request </a>
                                            Board </a> -->
                  </div>
                </div>
                <div class="dropend"> <a class="dropdown-item dropdown-toggle" href="#sidebar-cards"
                    data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false"> Settings
                  </a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" [ngClass]="{'active': activeRoute('/housekeeping/room-type')}"
                      routerLink="/housekeeping/room-type">
                      Room Type </a>
                    <a class="dropdown-item" routerLink="/housekeeping/locations"> Sectors </a>
                    <a class="dropdown-item" routerLink="/housekeeping/cleaning-preferences"
                      [ngClass]="{'active': activeRoute('/cleaning-preferences')}">
                      Cleaning Preferences </a>
                    <a class="dropdown-item" [ngClass]="{'active': activeRoute('/cleaning-profiles')}"
                      routerLink="/housekeeping/cleaning-profiles"> Cleaning
                      Profile </a>
                    <!-- href="{{ oldSiteURL }}/v2/#/house-keeping/assignment-rules" -->
                    <a [ngClass]="{'active': activeRoute('/assignment-rule')}" class="dropdown-item"
                      routerLink="/housekeeping/assignment-rule"> Assignment
                      Rules </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li *ngIf="permissionEvaluator.isUserHaveCrmPermission()" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#navbar-help" data-bs-toggle="dropdown" data-bs-auto-close="false"
            role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-user icon-font"></i></span>
            <span class="nav-link-title"> CRM </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{ show: activeRoute('loyalty') || activeRoute('guest') }">
            <!-- <a class="dropdown-item" href="" routerLink="/crm/dashboard">Dashboard</a> -->
            <a class="dropdown-item" (click)="closeNav()" [ngClass]="{ active: activeRoute('crm/guest') }"
              routerLink="/crm/guest">
              Guests/Customers
            </a>
            <!-- <a class="dropdown-item" href="{{ oldSiteURL }}/#/customers/list">Customer</a> -->
            <a *ngIf="permissionEvaluator.isUserHaveLoyaltyPermission()" class="dropdown-item" [ngClass]="{
                active: activeRoute('loyalty') && !activeRoute('loyalty/report')
              }" (click)="closeNav()" routerLink="/loyalty">Loyalty</a>
            <a *ngIf="permissionEvaluator.isUserHaveSegmentsPermission()" class="dropdown-item"
              [ngClass]="{ active: activeRoute('segments') }" href="{{ oldSiteURL }}/v2/#/segments">
              Segments
            </a>
            <a class="dropdown-item" [ngClass]="{ active: activeRoute('referral/list') }"
              href="{{ oldSiteURL }}/v1/#/referral/list">
              Referral
            </a>
            <a class="dropdown-item" [ngClass]="{ active: activeRoute('customers/import') }"
              href="{{ oldSiteURL }}/v1/#/customers/import">
              Import
            </a>

            <a *ngIf="permissionEvaluator.isUserHaveLoyaltyReportPermission()"
              [ngClass]="{ active: activeRoute('/loyalty/report') }" (click)="closeNav()" class="dropdown-item"
              routerLink="/loyalty/report">Loyalty Transactions
            </a>
          </div>
        </li>

        <li *ngIf="permissionEvaluator.isUserHaveMarketingPermission()" class="nav-item dropdown">
          <a [ngClass]="{ active: activeRoute('campaign') }" class="nav-link dropdown-toggle" href="#navbar-help"
            data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
            <span
              class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/lifebuoy -->
              <i class="ti ti-ad-2 icon-font"></i></span>
            <span class="nav-link-title"> Marketing </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{ 'show active': activeRoute('campaign') }">
            <a class="dropdown-item" (click)="closeNav()" [ngClass]="{ active: activeRoute('campaign') }"
              routerLink="/campaign">
              Campaign
            </a>
            <!-- <a class="dropdown-item" href="{{ oldSiteURL }}/#/marketing/campaign/summary"> Summary </a> -->
          </div>
        </li>

        <!-- todo report menu -->
        <!-- <li *ngIf="permissionEvaluator.isUserHaveReportPermission()" class="nav-item dropdown">
                    <a [ngClass]="{'active': activeRoute('reports')}" class="nav-link dropdown-toggle"
                        href="#navbar-help" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                        aria-expanded="false"> <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-chart-line icon-font"></i> </span> <span class="nav-link-title"> Reports
                        </span> </a>
                    <div class="dropdown-menu" [ngClass]="{'active': activeRoute('reports')}">
                        <a *ngIf="permissionEvaluator.isUserHaveGuestEntryReportPermission()"
                            [ngClass]="{'active': activeRoute('reports')}" (click)="closeNav()" class="dropdown-item "
                            href="{{ oldSiteURL }}/v3/#/reports"> Guest experience </a>
                        <a *ngIf="permissionEvaluator.isUserHaveWorkOrderReportPermission()"
                            [ngClass]="{'active': activeRoute('reports/wo')}" (click)="closeNav()" class="dropdown-item"
                            href="{{ oldSiteURL }}/#/reports/wo"> Work Order </a>
                        <a *ngIf="permissionEvaluator.isUserHaveCommunicationPermission()"
                            [ngClass]="{'active': activeRoute('reports/communication')}" (click)="closeNav()"
                            class="dropdown-item" href="{{ oldSiteURL }}/#/reports/communication"> Communication </a>

                        <a [ngClass]="{'active': activeRoute('reports/communication')}" (click)="closeNav()" 
                            class="dropdown-item" routerLink="/loyalty/report"> Transaction Report Loyalty
                        </a>
                        <div class="dropend"> <a class="dropdown-item dropdown-toggle" href="#sidebar-cards"
                                data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                                aria-expanded="false"> Guest </a>
                            <div class="dropdown-menu"> <a class="dropdown-item"> <span
                                        class="badge badge-sm bg-green text-uppercase ms-2">Coming Soon</span> </a>
                            </div>
                        </div>
                    </div>
                </li> -->

        <li *ngIf="permissionEvaluator.isUserHaveTemplatePermission()" class="nav-item dropdown">
          <a [ngClass]="{ active: activeRoute('templates') }" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
            data-bs-auto-close="false" role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-box-multiple"></i></span>
            <span class="nav-link-title"> Template Management </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{ 'show active': activeRoute('templates') }">
            <a class="dropdown-item" (click)="closeNav()" [ngClass]="{ active: activeRoute('/email') }"
              routerLink="/templates/email">
              Email Template
            </a>
            <a class="dropdown-item" (click)="closeNav()" [ngClass]="{ active: activeRoute('/whatsapp') }"
              routerLink="/templates/whatsapp">Whatsapp Template</a>
          </div>
        </li>

        <li *ngIf="permissionEvaluator.isUserHaveSurveyPermission()" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
            aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-checkbox icon-font"></i></span>
            <span class="nav-link-title"> Survey Management </span>
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="{{ oldSiteURL }}/v1/#/template/survey/categories">
              Survey Category
            </a>
            <a class="dropdown-item" href="{{ oldSiteURL }}/v1/#/template/survey">Survey</a>
          </div>
        </li>

        <!-- <li class="nav-item">
                    <a [ngClass]="{'active ': (activeRoute('/log-management/luggages'))}" (click)="closeNav()"
                        class="nav-link" routerLink="/log-management/luggages"><span
                            class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-luggage icon-font"></i>
                        </span> <span class="nav-link-title"> Luggages </span> </a>
                </li> -->

        <li *ngIf="permissionEvaluator.isUserHaveSettingsPermission()" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" [ngClass]="{ active: activeRoute('user') }" data-bs-toggle="dropdown"
            data-bs-auto-close="false" role="button" aria-expanded="false">
            <span class="nav-link-icon d-md-none d-lg-inline-block">
              <i class="ti ti-settings icon-font"></i>
            </span>
            <span class="nav-link-title"> Settings </span>
          </a>
          <div class="dropdown-menu" [ngClass]="{
              show:
                activeRoute('user-management') ||
                activeRoute('configuration-settings') ||
                activeRoute('groups') ||
                activeRoute('departments') ||
                activeRoute('divisions')
            }">
            <a *ngIf="permissionEvaluator.isUserHaveUserMnagementPermission()" class="dropdown-item" [ngClass]="{
                active:
                  activeRoute('user-management') &&
                  !activeRoute('groups') &&
                  !activeRoute('divisions') &&
                  !activeRoute('departments') &&
                  !activeRoute('configuration-settings')
              }" (click)="closeNav()" routerLink="/user-management">
              User Management
            </a>
            <!-- [ngClass]="{'active': activeRoute('user-management')}" -->

            <a *ngIf="permissionEvaluator.isUserHaveUserMnagementPermission()" class="dropdown-item"
              [ngClass]="{ active: activeRoute('user-management/groups') }" routerLink="/user-management/groups">
              User Group
            </a>
            <a class="dropdown-item" href="{{ oldSiteURL }}/v1/#/property/manage">
              Property Management
            </a>
            <a class="dropdown-item" [ngClass]="{ active: activeRoute('divisions') }" routerLink="/divisions">
              Divisions
            </a>
            <!-- <a class="dropdown-item" [ngClass]="{'active': activeRoute('departments')}" -->
            <a class="dropdown-item" (click)="closeNav()" [ngClass]="{ active: activeRoute('departments') }"
              routerLink="/departments">
              Departments
            </a>

            <a class="dropdown-item" [ngClass]="{ active: activeRoute('configuration-settings') }"
              routerLink="/configuration-settings" (click)="closeNav()">Configuration
            </a>
            <!-- <a class="dropdown-item" (click)="closeNav()"
                            href="{{ oldSiteURL }}/v3/#/guest-experience/setup"> Guest Experience Setup</a> -->
            <!-- <a class="dropdown-item" (click)="closeNav()" href="{{ oldSiteURL }}/v3/#/reviews/settings">
                            Review Account Setup</a> -->
          </div>
        </li>

        <!-- todo future -->
        <!--                 
                <li class="nav-item"> <a class="nav-link" href="{{ oldSiteURL }}/#/integration/view/stripe"><span
                            class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-plug icon-font"></i>
                        </span> <span class="nav-link-title"> Integrations </span> </a> </li>
                <li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                        data-bs-auto-close="false" role="button" aria-expanded="false"> <span
                            class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-user icon-font"></i></span> <span class="nav-link-title"> Guest
                            Management
                        </span> </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="{{ oldSiteURL }}/#/guest-manage/view"> Timeline Management </a>
                        <a class="dropdown-item" href="{{ oldSiteURL }}/#/guest-manage/checkout-survey"> Check-out
                            Survey
                            Management </a>
                    </div>
                </li>
                <li class="nav-item"> <a class="nav-link" href="{{ oldSiteURL }}/#/template/tripadvisor/view"><span
                            class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-info-circle icon-font"></i>
                        </span> <span class="nav-link-title"> Tripadvisor Management </span> </a>
                </li>
                <li class="nav-item"> <a class="nav-link" href="{{ oldSiteURL }}/#/settings/admin"><span
                            class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-settings icon-font"></i>
                        </span> <span class="nav-link-title"> Admin Settings</span> </a>
                </li> -->
      </ul>
    </div>
  </div>
</aside>

<!--todo commented codes if any use in nav -->

<!-- <li class="nav-item"> <a class="nav-link" routerLink="/budget">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-report-money fs-2"></i>
                    </span> <span class="nav-link-title">Budget </span> </a>
                </li> -->

<!-- <li *ngIf="permissionEvaluator.isUserHaveTemplatePermission()" class="nav-item dropdown"> <a
                        class="nav-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="false"
                        role="button" aria-expanded="false"> <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-box-multiple"></i></span> <span class="nav-link-title"> Template
                            Management
                        </span> </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="{{ oldSiteURL }}/#/template/email/view"> Email Template </a>
                        <a class="dropdown-item" href="{{ oldSiteURL }}/#/template/sms/view">SMS Template</a>
                        <a class="dropdown-item" href="{{ oldSiteURL }}/#/template/whatsapp/view">Whatsapp Template</a>
                    </div>
                </li> -->

<!-- <li class="nav-item"> <a class="nav-link" href="{{ oldSiteURL }}/#/index/dashboard"><span
                    class="nav-link-icon d-md-none d-lg-inline-block">
                    <i class="ti ti-home icon-font"></i>
                </span> <span class="nav-link-title"> Dashboard </span> </a> 
            </li> -->

<!-- conflict fix budget -->
<!--  -->
<!-- <li *ngIf="permissionEvaluator.isUserHaveBudgetPermission()" class="nav-item dropdown">
                    <a [ngClass]="{'active show': (activeRoute('budget'))}" class="nav-link dropdown-toggle"
                        href="#navbar-help" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button"
                        aria-expanded="false"> <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <i class="ti ti-report-money fs-2"></i></span> <span class="nav-link-title">
                            Budget </span> </a>
                    <div class="dropdown-menu" [ngClass]="{'show': (activeRoute('budget'))}">
                        <a class="dropdown-item" (click)="closeNav()"
                            [ngClass]="{'active ': (activeRoute('budget')&& !activeRoute('/budget/revenue')&& !activeRoute('/budget/expences'))}"
                            routerLink="/budget">Budget</a>
                        <a class="dropdown-item" (click)="closeNav()"
                            [ngClass]="{'active ': (activeRoute('/budget/revenue'))}"
                            routerLink="/budget/revenue">Revenue</a>
                        <a class="dropdown-item" (click)="closeNav()"
                            [ngClass]="{'active ': (activeRoute('/budget/expences'))}"
                            routerLink="/budget/expences">Expenses</a>
                       
                    </div>
                </li> -->
<!--  -->