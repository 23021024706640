import {
  PaginatedDataResponse,
  Status,
} from 'src/app/core/models/common.models';
import { Department } from '../../department/models/department.models';
import { User } from '../../user/models/user.models';
import { Gender } from '../../guest-crm/models/crm.models';

export class DutyLog {
  id?: number;
  createdOn?: string;
  updatedOn?: string;
  status?: string;
  log: string = '';
  attachments: string[] = [];
  mentions: string[] = [];
  departmentId: string;
  department?: Department;
  initiatedById: string;
  initiator?: User;
  archivedById: string;
  archiver: User;
  archivedOn: number;
  companyId: string;
  siteId: string;
  viewComment?: boolean = false;
  showComment?: boolean = false;
  commentNote?: string = '';
  comments?: number = 0;
  dutyLogComments?: PaginatedDataResponse<DutyLogComment[]>;
  commentList?: DutyLogComment[] = [];
}

export class PaginationData {
  dutyLogPage: number;
  dutyLogrowsPerPage: number;
  dutyLogPagesArray: number[] = [];
  commentPage: number;
  commentrowsPerPage: number;
  commentPageArray: number[] = [];

  constructor(
    dutyPage?: number,
    dutyRow?: number,
    commentPage?: number,
    commentRow?: number
  ) {
    this.dutyLogPage = dutyPage;
    this.dutyLogrowsPerPage = dutyRow;
    this.commentPage = commentPage;
    this.commentrowsPerPage = commentRow;
  }
}

export class DutyLogComment {
  id?: number;
  createdOn?: string;
  updatedOn?: string;
  status?: string;
  logId?: number;
  comment: string;
  initiatedById: string;
  initiator: User;
}

export class DateRange {
  from: number;
  to: number;
  field?: string;
}

export class DutyLogFilter {
  status: Status[];
  companyId: string;
  siteId?: string;
  page: number;
  rows?: number;
  loggedOn?: string;
}

export class PropertyNames {
  _id: string;
  entityName: string;
  propertyType: string;
  entityCode: null;
  status: string;
  sites: SitesData[];
}

export class SitesData {
  _id: string;
  entityName: string;
  propertyType: string;
  status: string;
  facilities: any[];
}

export class BaseFilter {
  status: string[];
  dateFilter: DateRange;
  page: number;
  rows: number;
  query: string;
  include: number[];
  exclude: number[];
  sortBy: string;
  sortIn: string;
  companyId: string;
  siteId: string;
  siteIds: string[];
  facilityId: string;
  date: any;
  departmentId: string;
  q: string;
}

export class InstrumentsFilter extends BaseFilter {
  // status: string[];
  // dateFilter: DateRange;
  // page: number;
  // rows: number;
  // query: string;
  // include: number[];
  // exclude: number[];
  // sortBy: string;
  // sortIn: string;
  // companyId: string;
  // siteId: string;
  // facilityId: string;
  // date: string;
  instrumentId: number;
  meterInstrumentId: number;
}
export class LogBookFilter extends BaseFilter {
  instrumentId: string;
  meterInstrumentId: string;
  sourceInstrumentId: string;
  instrument: Meters | DepartmentLogInstruments;
  interval;
  timeZone: string;
}

export class TransactionLogBookFilter extends BaseFilter {
  instrumentId: number;
  meterInstrumentId: number;
  sourceInstrumentId: number;
  departmentInstrumentId: number;
  // type : string;
  configurationId : number;
  timeZone : string;
  fnBWastageTypes : string[];
}

export class Instrument {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
  instrument: string;
  instrumentId: number;
  name: string;
  description: string;
  frequency: string;
  companyId: string;
  siteId: string;
  facilityId: string;
  instrumentParameters: string[];
  timeZone: string;
  departmentId: string;
  multiplicationFactor: number;
}
export class InstrumentMeter {
  instrument: string;
  instrumentId: string;
}

export class MeterInstrumentCreateRequest {
  instrument: Meters;
  name: string;
  description: string;
  frequency: Frequency;
  companyId: string;
  siteId: string;
  departmentId: string;
  initiatedById: string;
  instrumentParameters: string[];
  multiplicationFactor: number;
  replenishment: boolean;
}

export class DepartmentLogBookCreateRequest {
  instrument: DepartmentLogInstruments;
  name: string;
  description: string;
  departmentId: string;
  companyId: string;
  siteId: string;
  facilityId: string;
}

export class LogBook {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
  date: string;
  time: string;
  instrumentId: number;
  instrumentid: number;
  instrumentversion: number;
  instrumentstatus: string;
  instrumentcreatedOn: string;
  instrumentupdatedOn: string;
  instrumentname: string;
  instrumentcategory: string;
  instrumenttype: string;
  instrumentunit: string;
  instrumentdepartmentId: string;
  instrumentcompanyId: string;
  instrumentsiteId: string;
  instrumentfacilityId: string;
  value: string;
  remark: string;
  initiatedById: string;
  companyId: string;
  siteId: string;
  facilityId: string;
  instrument: string;
  meterInstrumentId: number;
  meterName: string;
  consumption: string;
  consumption1: string;
  consumption2: string;
  consumption3: string;
  consumption4: string;
  reading1: string;
  reading2: string;
  reading3: string;
  dieselConsumption: string;
  fuelConsumption: string;
  powerGenerated: string;
  workingHours: string;
  runningHours: string;
  departmentId: string;

  sourceInstrumentId: number;
  sourceName: string;
  hardness: string;
  chlorine: string;
  phLevel: string;
  alkalinity: string;
  cyanuricAcid: string;
  tds: string;
  csi: string;
  phosphates: string;
  copper: string;
  iron: string;
  bromine: string;
  peroxide: string;
  salt: string;
  borates: string;
  temperature: string;
  runningUnit: string;
  reading: string;
  entryTime1: string;
  entryTime2: string;
  entryTime3: string;
  entry1initiatedById: string;
  entry2initiatedById: string;
  entry3initiatedById: string;
  weightage;
  quantity;
}

export class Time {
  hour: number;
  minute: number;
  second: number;
  nano: number;
}

export class DepartmentLogBook {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: Status;
  instrument: DepartmentLogInstruments;
  name: string;
  description: string;
  departmentId: string;
  companyId: string;
  siteId: string;
  facilityId: string;
}

export class TransactionsLogBookEntryRequest {
  date: string;
  time: Time;
  fitCheckIns: number;
  gitCheckIns: number;
  fitCheckOuts: number;
  gitCheckOuts: number;
  checkInNote: string;
  checkOutNote: string;
  taxiBookings: number;
  taxiBookingsNote: string;
  foreignExchanges: number;
  foreignExchangesNote: string;
  dayOutVisitors: number;
  dayOutVisitorsNote: string;
  visitors: number;
  visitorsNote: string;
  tradeVisitors: number;
  tradeVisitorsNote: string;
  remark: string;
  initiatedById: string;
  companyId: string;
  siteId: string;
  facilityId: string;
}
export class TransactionsLogBookView {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
  date: string;
  time: Time;
  instrumentId: number;
  instrument: string;
  departmentInstrumentId: number;
  department: string;
  logbookName: string;
  fitCheckIns: number;
  fitCheckOuts: number;
  gitCheckIns: number;
  gitCheckOuts: number;
  checkInNote: string;
  checkOutNote: string;
  checkIns: number;
  checkOuts: number;
  taxiBookings: number;
  taxiBookingsNote: string;
  tradeVisitors: number;
  tradeVisitorsNote: string;
  dayOutVisitors: number;
  dayOutVisitorsNote: string;
  foreignExchanges: number;
  foreignExchangesNote: string;
  visitors: number;
  visitorsNote: string;
  remark: string;
  initiatedById: string;
  createdBy: User;
  departmentId: string;
  companyId: string;
  siteId: string;
  facilityId: string;
}

export class HkTransactionInfo {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
  date: string;
  noOfDeepClean: number;
  noOfCheckOutRooms: number;
  noOfStayOverRoomsCleaned: number;
  noOfOutOfServices: number;
  noOfDnD: number;
  noOfServiceLater: number;
  noOfExtraCleaningsDone: number;
  noOfBedDecoration: number;
  noOfPublicAreas: number;
  publicAreaNote: string;
  restaurant: number;
  restaurantNote: string;
  remark: string;
  initiatedById: string;
  companyId: string;
  siteId: string;
  createdBy: User;
}

export class ActivityMasterRequest {
  name: string;
  description: string;
  activityType: string;
  feeType: string;
  amount: number;
  companyId: string;
  siteId: string;
  facilityId: string;
  category: ActivityCategory;
  departmentId: string;
  occurrence: ActivityCategory;
  bannerImg: string;
  attachment: string;
}

export class ActivityMaster {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
  name: string;
  description: string;
  activityType: string;
  amount: number;
  companyId: string;
  siteId: string;
  facilityId: string;
  isPaid: boolean;
  category: ActivityCategory;
  activitySummary: ActivitySummary;
  bannerImg: string;
  occurrence: ActivityCategory;
  feeType: string;
}

export class ActivitiesLogBookRequest {
  date: any;
  time: any;
  activityId: number;
  activityType: ActivityType;
  name: string;
  description: string;
  activityAmount: number;
  thirdPartyAmount: number;
  managedById: string;
  noOfPeopleParticipated: number;
  roomNumbers: string;
  remark: string;
  initiatedById: string;
  departmentId: string;
  companyId: string;
  siteId: string;
  facilityId: string;
}

export class ActivitiesLogBook {
  list: ActivityList[];
  recordsPerPage: number;
  totalRecords: number;
  page: number;
  totalPages: number;
}

export class ActivityList {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
  date: string;
  time: Time;
  instrumentId: number;
  instrument: string;
  departmentInstrumentId: number;
  department: string;
  logbookName: string;
  activityId: number;
  name: string;
  description: string;
  activityAmount: number;
  thirdPartyAmount: number;
  managedById: string;
  managedByInfo: User;
  noOfPeopleParticipated: number;
  roomNumbers: string;
  remark: string;
  initiatedById: string;
  initiatedByInfo: User;
  departmentId: string;
  companyId: string;
  siteId: string;
  facilityId: string;
  manager: User;
  initiator: User;
  activityType: ActivityType;
  activityName: string;
}

export class ActivityMasterFilter extends BaseFilter {
  activityId: number;
  category: ActivityCategory;
  occurrences: string[];
  selectedDateRange: any;
  timeZone: string;
}
export class ActivitySummaryFilter extends BaseFilter {
  activityId: number;
  category: ActivityCategory;
}

export class ActivitySummary {
  lastUpdated: string;
  revenueCollected: number;
  noOfRecords: number;
}

export class ContractorLogBookRequest {
  date: string;
  time: Time;
  departmentId: string;
  purpose: string;
  vendor: string;
  project: string;
  workers: WorkerInfo[];
  companyId: string;
  siteId: string;
  facilityId: string;
  initiatedById: string;
  projectType: string;
}

export class ContractorsLogBookFilter extends BaseFilter {
  instrumentId: number;
  meterInstrumentId: number;
  sourceInstrumentId: number;
  departmentInstrumentId: number;
  activityId: number;
}
export class ContractorsSummaryFilter extends BaseFilter {
  instrumentId: number;
  meterInstrumentId: number;
  sourceInstrumentId: number;
  departmentInstrumentId: number;
  activityId: number;
}

export class ContractorsLogSummary {
  contractorsSummaries: ContractorsSummary[];
  totalSum: number;
}

export class ContractorsSummary {
  propertyName: string;
  summaries: Summary[];
  totalAmount: number;
}

export class Summary {
  count: number;
  total: number;
  department: any;
  departmentId: string;
}

export class WorkerInfo {
  name: string;
  ageGroup: string;
  gender: string;
  contractorType: string;
  hours: number;
  rate: number;
  amount: number;
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
}

export class ContractorsLogBook {
  date: string;
  time: Time;
  instrumentId: number;
  instrument: string;
  departmentInstrumentId: number;
  department: string;
  departmentSelected: Department;
  logbookName: string;
  departmentId: string;
  purpose: string;
  vendor: string;
  noOfContractors: number;
  project: string;
  amount: number;
  createdOn: string;
  initiatedById: string;
  initiatorInfo: User;
  id: any;
  siteId: string;
  companyId: string;
}

export class FnBTransactionCreateRequest {
  date: string;
  time: any;
  type: string;
  noOfPeople: number;
  mealPlan: number;
  others: number;
  noOfResidents: number;
  noOfNonResidents: number;
  remark: string;
  initiatedById: string;
  departmentId: string;
  companyId: string;
  siteId: string;
  facilityId: string;
  dish: string;
  qtyPrepared: number;
  qtyServed: number;
  leftovers: number;
}

export class FnBTransaction {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
  date: string;
  time: Time;
  type: string;
  noOfPeople: number;
  mealPlan: number;
  others: number;
  noOfResidents: number;
  noOfNonResidents: number;
  remark: string;
  initiatedById: string;
  createdBy: User;
  departmentId: string;
  companyId: string;
  siteId: string;
  facilityId: string;

  dish: string;
  qtyPrepared: number;
  qtyServed: number;
  leftovers: number;
}

export class HkLogReq {
  date: string;
  time: Time;
  noOfDeepClean: number;
  noOfCheckOutRooms: number;
  noOfStayOverRoomsCleaned: number;
  noOfOutOfServices: number;
  noOfDnD: number;
  noOfServiceLater: number;
  noOfExtraCleaningsDone: number;
  noOfBedDecoration: number;
  noOfPublicAreas: number;
  publicAreaNote: string;
  restaurant: number;
  restaurantNote: string;
  remark: string;
  initiatedById: string;
  departmentId: string;
  companyId: string;
  siteId: string;
  facilityId: string;
}

export class ActivityMasterPaginated {
  list: ActivityMasterList[];
  recordsPerPage: number;
  totalRecords: number;
  page: number;
  totalPages: number;
}

export class ActivityMasterList {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
  name: string;
  description: string;
  activityType: string;
  amount: number;
  companyId: string;
  siteId: string;
  facilityId: string;
  isPaid: boolean;
  category: ActivityCategory;
  activitySummary: ActivitySummary;
  bannerImg: string;
  occurrence: ActivityCategory;
  feeType: string;
}

export enum ContactorType {
  LOCAL = 'LOCAL',
  EXPATRIATE = 'EXPATRIATE',
}

export enum ActivityType {
  COMPLEMENTARY = 'COMPLEMENTARY',
  PAID = 'PAID',
}

export enum InstrumentCategory {
  METER = 'METER',
  CHEMICALS = 'CHEMICALS',
  DEPARTMENT = 'DEPARTMENT',
}

export enum Frequency {
  DAILY_ONCE = 'DAILY_ONCE',
  DAILY_TWICE = 'DAILY_TWICE',
  NO_LIMIT = 'NO_LIMIT',
}

export enum Meters {
  POWER = 'POWER',
  GENERATOR = 'GENERATOR',
  WATER = 'WATER',
  DIESEL = 'DIESEL',
  BOILER = 'BOILER',
  GAS = 'GAS',
  SWIMMING_POOL = 'SWIMMING_POOL',
}
export enum DepartmentLogInstruments {
  ACTIVITIES = 'ACTIVITIES',
  TRANSACTIONS = 'TRANSACTIONS',
}

export enum ActivityCategory {
  SEASONAL = 'SEASONAL',
  DAILY = 'DAILY',
  REGULAR = 'REGULAR',
}

export enum FnbType {
  BREAK_FAST = 'BREAK_FAST',
  LUNCH = 'LUNCH',
  DINNER = 'DINNER',
}

export enum InstrumentTypeIcons {
  SWIMMING_POOL = 'assets/images/logs/swimming_pool.svg',
  POWER = 'assets/images/logs/electricity.svg',
  ELECTRICITY = 'assets/images/logs/electricity.svg',
  BOILER = 'assets/images/logs/boiler.svg',
  DIESEL_GENERATOR = 'assets/images/logs/generator.svg',
  DIESEL = 'assets/images/logs/generator.svg',
  GENERATOR = 'assets/images/logs/generator.svg',
  WATER = 'assets/images/logs/water.svg',
  GAS = 'assets/images/logs/gas.svg',
  POOL = 'assets/images/logs/water.svg',
  VISITOR = 'assets/images/logs/vistor.svg',
  UTILITY_STAFF = '/assets/images/logs/utility_staff.svg',
  REVENUE = 'assets/images/logs/revenue.svg',
  DEPARTMENTS = 'assets/images/logs/departments.svg',
}

export enum InstrumentTypeIconsNew {
  SWIMMING_POOL = 'assets/images/logs/swimming_pool.svg',
  power = 'assets/images/logs/electricity.svg',
  ELECTRICITY = 'assets/images/logs/electricity.svg',
  boiler = 'assets/images/logs/boiler.svg',
  DIESEL_GENERATOR = 'assets/images/logs/generator.svg',
  diesel = 'assets/images/logs/generator.svg',
  generator = 'assets/images/logs/generator.svg',
  water = 'assets/images/logs/water.svg',
  gas = 'assets/images/logs/gas.svg',
  POOL = 'assets/images/logs/water.svg',
  VISITOR = 'assets/images/logs/vistor.svg',
  UTILITY_STAFF = '/assets/images/logs/utility_staff.svg',
  REVENUE = 'assets/images/logs/revenue.svg',
  DEPARTMENTS = 'assets/images/logs/departments.svg',
}

export const AGE_GROUP = [
  '18 to 25',
  '25 to 30',
  '30 to 35',
  '35 to 40',
  '40 to 45',
  '45 to 50',
  '50 to 55',
];

export class PropertyList {
  _id: string;
  entityName: string;
  propertyType?: string;
  status?: string;
  entityCode?: string;
  divisionId?: string;
  clouds?: string[];
  facilities?: string[];
}

export class InstrumentParameters {
  parameter: string;
  fieldName: string;
  displayName: string;
}

export class AddReadingPayload {
  sourceInstrumentId?: number;
  remark?: string;
  initiatedById?: string;
  date: string;
  time?: string;
  consumption?: string;
  consumption1?: string;
  consumption2?: string;
  consumption3?: string;
  consumption4?: string;
  reading1: string;
  reading2: string;
  reading3: string;
  meterInstrumentId?: number;
  runningHours?: string;
  fuelConsumption: string;
  workingHours: string;
  powerGenerated: string;
  id;
  reading;
  weightage;
  quantity: string;
  fuel: string;
}

export class CreateIncidentPayload {
  id: number;
  createdOn: string;
  updatedOn: string;
  incidentStatus: string;
  name: string;
  incidentTypeId: number;
  incidentType: IncidentType;
  location: string;
  dateOfIncident: string;
  reportedDate: string;
  reportedTime: string;
  timeOfIncident: string;
  severity: string;
  description: string;
  actionsTaken: string;
  rootCauses: string;
  correctiveActions: string;
  remarks: string;
  initiatedById: string;
  companyId: string;
  siteId: string;
  impactedIndividuals: ImpactedIndividual[];
  involvedIndividuals: InvolvedIndividual[];
  propertyIncidentId: number;
  facilityId: string;
  reporter: InvolvedIndividual;
  incidentNumber: number;
  // reviewerId : string;
  approverId: string;
  managerId: string;
}

export class IncidentType {
  id: number;
  name: string;
  description: string;
  companyId: string;
  siteId: string;
}

export class ImpactedIndividual {
  incidentId: number;
  name: string;
  email: string;
  phone: string;
  referenceId: string;
  type: string;
  gender: string;
  age: number;
  details: string;
  room: string;
  nationality: string;
  companyId: string;
  siteId: string;
  facilityId: string;
  id: number;
  roomNo: string;
}

export class InvolvedIndividual {
  incidentId: number;
  name: string;
  email: string;
  phone: string;
  type: string;
  companyId: string;
  siteId: string;
  facilityId: string;
  id: number;
}

export interface IncidentData {
  list: IncidentsList[];
  recordsPerPage: number;
  totalRecords: number;
  page: number;
  totalPages: number;
}


// export class FnbWasteage {
//   date: string;
//   time: string;
//   restaurantName: string;
//   unit: string;
//   type: FnbWasteType;
//   quantity: number;
//   departmentId: string;
//   companyId: string;
//   siteId: string;
//   facilityId: string;
//   id: number;
//   createdOn: string;
//   updatedOn: string;
//   status: string;
//   initiatedById: string;
//   configurationId : number
// }

export class FnbWastageList {
  list: FnbWastage[]
  recordsPerPage: number
  totalRecords: number
  page: number
  totalPages: number
}

export class FnbWastage extends BaseFilter {
  id: number
  createdOn: string
  updatedOn: string
  time: string
  configurationId: number
  configuration: Configuration
  unit: string
  type: string
  quantity: number
  initiatedById: string
  fnBWastageTypes : string;
}

export interface Configuration {
  id: number
  createdOn: string
  updatedOn: string
  status: string
  name: string
  description: string
  companyId: string
  siteId: string
  facilityId: string
}

export enum FnbWasteType {
  COOKED = 'COOKED',
  UNCOOKED = 'UNCOOKED',
}

export class FNBWastageConfigPayload {
  name: string
  description : string;
  companyId: string
  siteId: string
  facilityId: string
  initiatedById : string;
}

export class FNBKitchens {
  id: number
  createdOn: string
  updatedOn: string
  status: string
  name: string
  source: string
  companyId: string
  siteId: string
  facilityId: string
  description : string;
}


export class IncidentsList {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
  propertyIncidentId: number;
  name: string;
  incidentTypeId: number;
  incidentType: IncidentType;
  location: string;
  dateOfIncident: string;
  timeOfIncident: string;
  reportedDate: string;
  reportedTime: string;
  severity: string;
  details: string;
  actionsTaken: string;
  rootCauses: string;
  correctiveActions: string;
  remarks: string;
  initiatedById: string;
  approvedById: string;
  companyId: string;
  siteId: string;
  facilityId: string;
  reporter: InvolvedIndividual;
  incidentNumber: number;
  incidentStatus: string;
}

export class IncidentPeople {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
  incidentId: number;
  name: string;
  email: string;
  phone: string;
  type: string;
  companyId: string;
  siteId: string;
  roomNo: string;
  referenceId: string;
}

export class IncidentApproverController {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
  incidentId: number;
  reviewerId: string;
  comment: string;
  requestedOn: number;
  verifiedOn: number;
  displayName: string;
}

export class IncidentNotePayload {
  userId: string;
  comment: string;
}

export class IncidentNotes {
  id: number;
  createdOn: string;
  updatedOn: string;
  status: string;
  incidentId: number;
  userId: string;
  comment: string;
  displayName: string;
}

export class IncidentReviewer {
  reviewerId: string;
  role: string;
}

export class HRConnectFilter extends BaseFilter {
  connectStatus: Status[];
}

export class DashboardInstrument {
  instrument: string;
  instrumentId: number;
  instrumentOverview: InstrumentOverview;
}

export class InstrumentOverview {
  meterGeneratorInfo: MeterGeneratorInfo;
  meterPowerInfo: MeterPowerInfo;
  meterDieselInfo: MeterDieselInfo;
  meterBoilerInfo: MeterBoilerInfo;
  meterGasInfo: MeterGasInfo;
  meterWaterInfo: MeterWaterInfo;
  siteId: string;
}

export class MeterGeneratorInfo {
  powerGenerated: number;
  fuelConsumption: number;
  workingHours: number;
  workingTime: string;
}

export class MeterPowerInfo {
  consumption: number;
  unit: string;
}

export class MeterDieselInfo {
  consumption: number;
  runningHours: number;
  unit: string;
}

export class MeterBoilerInfo {
  consumption: number;
  workingHours: number;
  unit: string;
}

export class MeterGasInfo {
  consumption: number;
  unit: string;
}

export class MeterWaterInfo {
  consumption: number;
  unit: string;
}

export class Usage {
  consumption: number;
  workingHours: number;
  unit: string;
  powerGenerated: number;
  fuelConsumption: number;
}

export class InstrumentInfos {
  consumption: number;
  workingHours: number;
  runningHours: number;
  fuelConsumption: number;
  powerGenerated: number;
  workingTime: string;
  unit: string;
  meterInstrumentId: number;
  name: string;
  siteId: string;
}
