<div *ngIf="showForm">
  <form class="mb-3" *ngIf="formData?.title" [formGroup]="inspectionForm">
    <div class="loading-overlay" *ngIf="loading">
      <div class="loading"></div>
    </div>
    <div class="card m-3 rounded-4">
      <div class="card-header">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <div>
            <div
              *ngIf="!isInputVisible"
              class="d-flex gap-2 align-items-center"
            >
              <h3 style="font-size: 20px" class="card-title">
                {{ inspectionName }}
              </h3>
              <span (click)="showInput()">
                <i class="ti ti-edit cursor-pointer text-blue"></i>
              </span>
            </div>

            <div *ngIf="isInputVisible" class="input-wrapper">
              <input
                class="form-control custom-input card-title"
                type="text"
                [(ngModel)]="inspectionName"
                [ngModelOptions]="{ standalone: true }"
                [style.width]="inputWidth"
                (input)="updateWidth(inspectionName); saveName()"
                placeholder="Type here"
              />
            </div>

            <div class="fs-5 text-muted pt-1">{{ formData?.description }}</div>
            <div *ngIf="lastSavedData">
              <div class="fs-6 text-muted pt-2" *ngIf="isSaved">
                Last edited on {{ lastSavedData | date : "dd MMM yyyy" }} at
                {{ lastSavedData | date : "hh:mma" }}
              </div>
              <div class="fs-6 text-muted pt-2" *ngIf="!isSaved">Saving...</div>
            </div>
            <div class="d-block d-md-none pt-2">
              Score : {{ score.answeredQuestions }} /
              {{ score.totalQuestions }} ({{
                score.totalScore | number : "1.0-0"
              }}%)
            </div>
          </div>
          <div class="d-none d-md-block">
            <div class="score">
              <div class="text-muted">Score</div>
              <div class="fs-3">
                {{ score.answeredQuestions }} / {{ score.totalQuestions }} ({{
                  score.totalScore | number : "1.0-0"
                }}%)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="accordion" id="accordion-example">
          <div
            class="accordion-item"
            *ngFor="let category of formData.categories; let i = index"
          >
            <h2 class="accordion-header" [attr.id]="'heading-' + (i + 1)">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapse-' + (i + 1)"
                [attr.aria-expanded]="i === 0 ? 'true' : 'false'"
              >
                <span class="cateogy-header">
                  {{ category.name }}
                </span>
              </button>
            </h2>
            <div
              [attr.id]="'collapse-' + (i + 1)"
              [class.show]="i === 0"
              class="accordion-collapse collapse"
              data-bs-parent="#accordion-example"
            >
              <div class="accordion-body pt-0">
                <div *ngFor="let element of category?.questions; let j = index">
                  <!-- <div class="fs-question-title pb-3 ">
                  {{(j+1) + '. ' + element.title }}
                </div> -->

                  <label
                    class="form-label mb-3"
                    [ngClass]="{ required: element?.required }"
                  >
                    {{ j + 1 + ". " + element.title }}
                  </label>

                  <div class="mb-3">
                    <!-- Yes/No/Not Section -->
                    <div
                      *ngIf="
                        element.type === 'YES_NO_NOT_APPLICABLE' ||
                        element.type === 'YES_NO' ||
                        element.type === 'YES_NO_PARTIALLY'
                      "
                    >
                      <div class="col mb-3">
                        <div
                          class="form-selectgroup"
                          *ngFor="let choice of element.choices"
                        >
                          <label class="form-selectgroup-item pe-2">
                            <input
                              type="radio"
                              [formControlName]="
                                'category-' + i + '-element-' + j
                              "
                              [value]="choice"
                              class="form-selectgroup-input"
                              (change)="onRadioChange()"
                            />
                            <span class="form-selectgroup-label">
                              {{ choice }}
                            </span>
                          </label>
                        </div>
                        <div
                          *ngIf="
                            inspectionForm.get(
                              'category-' + i + '-element-' + j
                            ).invalid &&
                            inspectionForm.get(
                              'category-' + i + '-element-' + j
                            ).touched
                          "
                          class="invalid-feedback"
                        >
                          This field is required.
                        </div>
                      </div>
                    </div>

                    <!-- Text Field -->
                    <div class="mb-3" *ngIf="element.type === 'INPUT'">
                      <input
                        type="text"
                        class="form-control"
                        [formControlName]="'category-' + i + '-element-' + j"
                        (input)="onInputChange(i, j, $event)"
                      />
                      <div
                        *ngIf="
                          inspectionForm.get('category-' + i + '-element-' + j)
                            .invalid &&
                          inspectionForm.get('category-' + i + '-element-' + j)
                            .touched
                        "
                        class="invalid-feedback"
                      >
                        This field is required.
                      </div>
                    </div>
                    <!-- Long Text -->
                    <div class="mb-3" *ngIf="element.type === 'TEXT'">
                      <textarea
                        (input)="onTextAreaChange(i, j, $event)"
                        class="form-control"
                        [formControlName]="'category-' + i + '-element-' + j"
                      ></textarea>
                      <div
                        *ngIf="
                          inspectionForm.get('category-' + i + '-element-' + j)
                            .invalid &&
                          inspectionForm.get('category-' + i + '-element-' + j)
                            .touched
                        "
                        class="invalid-feedback"
                      >
                        This field is required.
                      </div>
                    </div>

                    <!-- Select -->
                    <div class="mb-3" *ngIf="element.type === 'SELECT'">
                      <ng-select
                        (change)="onSelectChanged()"
                        [formControlName]="'category-' + i + '-element-' + j"
                      >
                        <ng-option
                          *ngFor="let choice of element.choices"
                          [value]="choice"
                          >{{ choice }}</ng-option
                        >
                      </ng-select>
                      <div
                        *ngIf="
                          inspectionForm.get('category-' + i + '-element-' + j)
                            .invalid &&
                          inspectionForm.get('category-' + i + '-element-' + j)
                            .touched
                        "
                        class="invalid-feedback"
                      >
                        This field is required.
                      </div>
                    </div>

                    <!--Multi Select -->
                    <div class="mb-3" *ngIf="element.type === 'MULTI_SELECT'">
                      <ng-select
                        (change)="onSelectChanged()"
                        [multiple]="true"
                        [formControlName]="'category-' + i + '-element-' + j"
                      >
                        <ng-option
                          *ngFor="let choice of element.choices"
                          [value]="choice"
                          >{{ choice }}</ng-option
                        >
                      </ng-select>
                      <div
                        *ngIf="
                          inspectionForm.get('category-' + i + '-element-' + j)
                            .invalid &&
                          inspectionForm.get('category-' + i + '-element-' + j)
                            .touched
                        "
                        class="invalid-feedback"
                      >
                        This field is required.
                      </div>
                    </div>

                    <!-- Option -->
                    <div class="mb-3" *ngIf="element.type === 'RADIO'">
                      <div *ngFor="let choice of element.choices">
                        <label class="form-check">
                          <input
                            (change)="onSelectChanged()"
                            class="form-check-input"
                            type="radio"
                            [value]="choice"
                            [formControlName]="
                              'category-' + i + '-element-' + j
                            "
                          />
                          <span class="form-check-label">{{ choice }}</span>
                        </label>
                      </div>
                      <div
                        *ngIf="
                          inspectionForm.get('category-' + i + '-element-' + j)
                            .invalid &&
                          inspectionForm.get('category-' + i + '-element-' + j)
                            .touched
                        "
                        class="invalid-feedback"
                      >
                        This field is required.
                      </div>
                    </div>

                    <div class="d-flex gap-2">
                      <!-- File Upload -->
                      <div *ngIf="element.fileUploadRequired" class="">
                        <button
                          type="button"
                          class="btn"
                          (click)="openImageUpload(i, j)"
                        >
                          <i class="fa fa-camera text-blue"></i>
                        </button>
                      </div>

                      <!-- Comment Box -->
                      <div *ngIf="element.commentRequired" class="">
                        <button
                          type="button"
                          class="btn"
                          (click)="toggleComment(j, i)"
                        >
                          <i class="ti ti-message text-blue"></i>
                        </button>
                      </div>

                      <!-- Workorder -->
                      <!-- <div *ngIf="element.worequired" class="">
                        <button type="button" class="btn">
                          <i class="ti ti-settings-dollar text-blue"></i>
                        </button>
                      </div> -->
                    </div>
                  </div>

                  <div class="mb-3" *ngIf="commentVisibility[i][j]">
                    <textarea
                      placeholder="Enter comments"
                      class="form-control"
                      (input)="updateComment(j, i, $event)"
                      [value]="formData.categories[i].questions[j].comments"
                    ></textarea>
                  </div>

                  <div
                    class="image-section"
                    *ngIf="
                      element.attachments && element.attachments.length > 0
                    "
                  >
                    <div
                      *ngFor="
                        let attachment of element.attachments;
                        let k = index
                      "
                      class="image-wrapper"
                    >
                      <div class="image-container">
                        <img
                          (click)="
                            viewAttachments(
                              attachment,
                              getFileExtension(attachment)
                            )
                          "
                          [src]="getImage(attachment)"
                          alt="Uploaded Image"
                          class="uploaded-image"
                        />
                        <!-- Delete icon at the top-right corner -->
                        <!-- <i class="delete-icon">&#10006;</i> -->
                        <span
                          class="cursor-pointer delete-icon"
                          (click)="removeImage(i, j, k)"
                        >
                          <i
                            style="font-size: 22px"
                            class="ti ti-trash text-red"
                          ></i>
                        </span>
                        <!-- Example using an "X" for the delete icon -->
                      </div>
                    </div>
                  </div>

                  <!-- <div
                    class="image-section"
                    *ngIf="
                      element.attachments && element.attachments.length > 0
                    "
                  >
                    <div *ngFor="let attachment of element.attachments">
                      <img
                        [src]="getImage(attachment)"
                        alt="Uploaded Image"
                        class="uploaded-image"
                      />
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex gap-3 justify-content-end m-3 pb-3">
      <!-- <button type="submit" (click)="saveAsDraft()" class="btn btn-secondary">
        Save as draft
      </button> -->
      <button type="submit" (click)="onSubmit()" class="btn btn-primary">
        Submit Response
      </button>
    </div>
  </form>
</div>

<div *ngIf="showSuceessSection">
  <div class="page page-center">
    <div class="container-tight py-4">
      <div class="empty">
        <div class="empty-img">
          <img src="assets\images\error\Completed.svg" alt="" />
        </div>
        <p class="empty-title">Inspection Completed Successfully</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showUnAuthorised">
  <div class="page page-center">
    <div class="container-tight py-4">
      <div class="empty">
        <div class="empty-img">
          <img src="assets\images\error\Completed.svg" alt="" />
        </div>
        <p class="empty-title">Inspection Already Submitted</p>
        <p class="empty-subtitle text-secondary">
          {{ unAuthorisedMessage }}
        </p>
      </div>
    </div>
  </div>
</div>
