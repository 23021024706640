import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class UtilServiceService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getFile(file) {
    let params = new HttpParams();
    params = params.set('filename', file);
    return this.http.get(`${this.logBook}api/general/fetch?filename=` + file, {
      responseType: 'blob',
    });
  }

  uploadFile(file, keepOriginalName?: boolean): Observable<any> {
    let params = new HttpParams();
    if (keepOriginalName) {
      params = params.set('keepOriginalName', keepOriginalName);
    }
    return this.http.post<any>(`${this.logBook}api/general/upload`, file, {
      params: params,
    });
  }

  uploadFileInspection(file, keepOriginalName?: boolean): Observable<any> {
    let params = new HttpParams();
    if (keepOriginalName) {
      params = params.set('keepOriginalName', keepOriginalName);
    }
    return this.http.post<any>(`${this.cixUrl}/general/upload`, file, {
      params: params,
    });
  }
}
