<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th *ngFor="let header of tableHeaders">{{ header }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of tableData">
                <td *ngFor="let header of tableHeaders">
                    <!-- Check if the column should display a badge -->
                    <span *ngIf="badgeColumns.includes(header); else regularCell"
                        [ngClass]="getBadgeClass(row[header])">
                        {{ row[header] }}
                    </span>
                    <!-- Regular cell content if not a badge column -->
                    <ng-template #regularCell>{{ row[header] }}</ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</div>