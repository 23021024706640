import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormData } from '../../models/inspection';
import { ChecklistService } from 'src/app/modules/checklist/services/checklist.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ImageUploadDrawComponent } from '../image-upload-draw/image-upload-draw.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { UniversalFileViewerComponent } from '../universal-file-viewer/universal-file-viewer.component';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.css'],
})
export class InspectionComponent implements OnInit, OnDestroy {
  loading: boolean = true; // Start with loading true
  inspectionForm: FormGroup;
  commentVisibility: boolean[][] = [];
  checklistId: string;
  identifier: string;
  formData: any;
  public subscription1: Subscription;
  showImageModal = false;
  currentCategoryIndex: number;
  currentElementIndex: number;
  selectedImage: HTMLImageElement;
  isMobile = false;
  showSuceessSection: boolean = false;
  showUnAuthorised: boolean = false;
  showForm: boolean = true;
  unAuthorisedMessage: string;
  public cixUrl: string = environment.cixURL;
  private commentInputSubject = new Subject<{
    categoryIndex: number;
    elementIndex: number;
    comment: string;
  }>();
  private inputChangeSubject = new Subject<{
    categoryIndex: number;
    elementIndex: number;
    value: string;
  }>();

  private textAreaChangeSubject = new Subject<{
    categoryIndex: number;
    elementIndex: number;
    value: string;
  }>();

  private saveNameSubject = new Subject<string>();

  private modalRef: NgbModalRef;

  lastSavedData: any;
  isSaved: boolean = true;

  score: any = {
    totalQuestions: 0,
    answeredQuestions: 0,
    totalScore: 0,
  };

  inspectionName: string;
  inputWidth: string = 'auto';

  isInputVisible: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient, // Inject HttpClient for API call
    private checklistService: ChecklistService,
    private toaster: ToastrService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal
  ) {
    this.commentInputSubject
      .pipe(
        debounceTime(2000), // Wait for 500ms after the last keystroke
        distinctUntilChanged((prev, curr) => prev.comment === curr.comment), // Trigger only if the value is different from the previous one
        switchMap((data) => this.handleCommentInput(data)) // Handle input when conditions are met
      )
      .subscribe();

    this.inputChangeSubject
      .pipe(
        debounceTime(500), // Wait for 500ms after the last input
        distinctUntilChanged((prev, curr) => prev.value === curr.value), // Only trigger if the value changes
        switchMap((data) => this.handleInputChange(data)) // Handle input when conditions are met
      )
      .subscribe();

    this.textAreaChangeSubject
      .pipe(
        debounceTime(500), // Wait for 500ms after the last input
        distinctUntilChanged((prev, curr) => prev.value === curr.value), // Only trigger if the value changes
        switchMap((data) => this.handleTextAreaChange(data)) // Handle input when conditions are met
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    if (this.commentInputSubject) {
      this.commentInputSubject.unsubscribe();
    }
    if (this.inputChangeSubject) {
      this.inputChangeSubject.unsubscribe();
    }
    if (this.textAreaChangeSubject) {
      this.textAreaChangeSubject.unsubscribe();
    }
  }

  ngOnInit() {
    this.saveNameSubject.pipe(debounceTime(500)).subscribe(() => {
      this.saveNameDebounced();
    });

    this.subscription1 = this.route.paramMap.subscribe((params: ParamMap) => {
      this.checklistId = params.get('checklistId');
      this.identifier = params.get('identifier');

      this.fetchFormData(this.checklistId, this.identifier, true); // Fetch formData from API
    });
  }

  fetchFormData(
    checklistId: string,
    identifier: string,
    enableLoading?: boolean
  ) {
    if (enableLoading == false) {
      this.loading = false;
    } else {
      this.loading = true;
    }
    this.isSaved = false;
    this.checklistService.getCIXInstance(checklistId, identifier).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.formData = res.data.checklist;
        this.inspectionName = res?.data?.inspection?.name
          ? res.data.inspection.name
          : res.data.instanceName;
        this.updateWidth(this.inspectionName);
        this.initializeForm();
        this.isSaved = true;

        res.data.checklist.categories.forEach((category) => {
          this.score.totalQuestions += category.questions.length;
        });

        console.log('Total number of questions:', this.score);

        // Check if inspection data and checklist exist
        if (
          res.data.inspection &&
          res.data.inspection.categories &&
          this.formData.categories
        ) {
          this.lastSavedData = res.data.inspection.updatedOn;
          res.data.inspection.categories.forEach((category) => {
            const answeredQuestions = category.answers.filter(
              (answer) => answer.answer !== null
            );
            this.score.answeredQuestions += answeredQuestions.length;
          });

          this.score.totalScore =
            (this.score.answeredQuestions / this.score.totalQuestions) * 100;

          console.log('totalScore:', this.score);

          this.formData.inspection = res.data.inspection;

          // Iterate through checklist categories and match with inspection answers
          this.formData.categories.forEach(
            (checklistCategory: any, categoryIndex: number) => {
              const inspectionCategory =
                this.formData.inspection.categories[categoryIndex];

              // Check if inspectionCategory and answers exist
              if (inspectionCategory && inspectionCategory.answers) {
                checklistCategory.questions.forEach(
                  (question: any, questionIndex: number) => {
                    const inspectionAnswer = inspectionCategory.answers.find(
                      (ans: any) => ans.id === question.id
                    );

                    // If a matching answer is found, populate the form control with the answer
                    if (inspectionAnswer) {
                      const formControlName = `category-${categoryIndex}-element-${questionIndex}`;
                      this.inspectionForm
                        .get(formControlName)
                        ?.setValue(inspectionAnswer.answer);

                      // this.commentVisibility[categoryIndex][questionIndex] =
                      //   !inspectionAnswer.comment;
                    }
                  }
                );
              }
            }
          );

          this.formData.categories.forEach((category, categoryIndex) => {
            // Get the corresponding inspection category (if it exists)
            const inspectionCategory =
              this.formData.inspection.categories[categoryIndex];

            if (inspectionCategory) {
              // Iterate through questions in the current category
              category.questions.forEach((question, questionIndex) => {
                // Check if the question.id matches with the inspection answer.id
                const matchingAnswer = inspectionCategory.answers.find(
                  (answer) => answer.id === question.id
                );

                // If a match is found, assign the answer value to a new "answer" field in the question
                if (matchingAnswer) {
                  question.comments = matchingAnswer.comment
                    ? matchingAnswer.comment
                    : null;

                  question.attachments = matchingAnswer.attachments
                    ? matchingAnswer.attachments
                    : null;
                }
              });
            }
          });
        }
      },
      error: (err: any) => {
        console.log(err);
        this.toaster.remove;
        this.loading = false;
        this.showForm = false;
        this.showSuceessSection = false;
        this.showUnAuthorised = true;
        this.unAuthorisedMessage = err.message;
      },
    });
  }

  getInspectionQuestins(checklistId) {
    this.checklistService.getChecklistById(checklistId).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.formData = res.data;
        this.initializeForm();
      },
      error: (err: any) => {
        console.log(err);
        // this.toaster.error(err.statusText);
        this.showForm = false;
        this.showSuceessSection = false;
        this.showUnAuthorised = true;
        this.unAuthorisedMessage = err.statusText;
        this.loading = false;
      },
    });
  }

  initializeForm() {
    let group: any = {};

    this.formData.categories.forEach((category, i) => {
      // Initialize the commentVisibility array for each category
      this.commentVisibility[i] = [];

      category.questions.forEach((element, j) => {
        // Check if the element is required
        const controlValidators = element.required ? [Validators.required] : [];
        group['category-' + i + '-element-' + j] = ['', controlValidators];

        // Initialize the visibility for comments
        this.commentVisibility[i][j] = false; // Initialize comment visibility
      });
    });

    this.inspectionForm = this.fb.group(group);
  }

  toggleComment(elementIndex: number, categoryIndex: number) {
    this.commentVisibility[categoryIndex][elementIndex] =
      !this.commentVisibility[categoryIndex][elementIndex];

    // if (!this.commentVisibility[categoryIndex][elementIndex]) {
    //   this.formData.categories[categoryIndex].questions[elementIndex].comments =
    //     '';
    // }
  }

  updateComment(elementIndex: number, categoryIndex: number, event: any) {
    this.formData.categories[categoryIndex].questions[elementIndex].comments =
      event.target.value;
    this.commentInputSubject.next({
      categoryIndex,
      elementIndex,
      comment: event.target.value,
    });
  }

  handleCommentInput(data: {
    categoryIndex: number;
    elementIndex: number;
    comment: string;
  }) {
    // Update the formData with the new comment
    this.formData.categories[data.categoryIndex].questions[
      data.elementIndex
    ].comments = data.comment;

    // Call saveAsDraft to save the changes
    this.saveAsDraft();

    return []; // Return an empty array for the switchMap
  }

  onInputChange(categoryIndex: number, elementIndex: number, event: any) {
    const newValue = event.target.value;

    // Push the input change to the subject
    this.inputChangeSubject.next({
      categoryIndex,
      elementIndex,
      value: newValue,
    });
  }

  handleInputChange(data: {
    categoryIndex: number;
    elementIndex: number;
    value: string;
  }) {
    // Update the formData with the new value
    this.inspectionForm
      .get(`category-${data.categoryIndex}-element-${data.elementIndex}`)
      .setValue(data.value);

    // Call saveAsDraft to save the changes
    this.saveAsDraft();

    return []; // Return an empty array for the switchMap
  }

  onTextAreaChange(categoryIndex: number, elementIndex: number, event: any) {
    const newValue = event.target.value;

    // Push the input change to the subject
    this.inputChangeSubject.next({
      categoryIndex,
      elementIndex,
      value: newValue,
    });
  }

  handleTextAreaChange(data: {
    categoryIndex: number;
    elementIndex: number;
    value: string;
  }) {
    // Update the formData with the new value
    this.inspectionForm
      .get(`category-${data.categoryIndex}-element-${data.elementIndex}`)
      .setValue(data.value);

    // Call saveAsDraft to save the changes
    this.saveAsDraft();

    return []; // Return an empty array for the switchMap
  }

  handleNameChange(data: {
    categoryIndex: number;
    elementIndex: number;
    value: string;
  }) {
    // Update the formData with the new value
    this.inspectionForm
      .get(`category-${data.categoryIndex}-element-${data.elementIndex}`)
      .setValue(data.value);

    // Call saveAsDraft to save the changes
    this.saveAsDraft();

    return []; // Return an empty array for the switchMap
  }

  saveName() {
    this.saveNameSubject.next(this.inspectionName);
  }

  saveNameDebounced() {
    this.saveAsDraft();
  }

  saveAsDraft() {
    const formValue = this.inspectionForm.value;
    const response = this.buildResponse3(formValue);
    this.isSaved = false;

    this.checklistService.postInspection(response).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.isSaved = true;
        this.lastSavedData = res.data.updatedOn;
        this.score.answeredQuestions = 0;
        // res.data.categories.forEach((category) => {
        //   this.score.answeredQuestions += category.answers.length;
        // });

        res.data.categories.forEach((category) => {
          const answeredQuestions = category.answers.filter(
            (answer) => answer.answer !== null
          );
          this.score.answeredQuestions += answeredQuestions.length;
        });

        console.log('saveAsDraft', this.score);

        this.score.totalScore =
          (this.score.answeredQuestions / this.score.totalQuestions) * 100;
      },
      error: (err: any) => {
        console.log(err);
        if (err.status == '400') {
          console.log('error', err);

          this.toaster.error(err.error);
        } else {
          this.showForm = false;
          this.showSuceessSection = false;
          this.showUnAuthorised = true;
          this.unAuthorisedMessage = err.statusText;
        }
      },
    });
  }

  onSubmit() {
    if (this.inspectionForm.invalid) {
      this.inspectionForm.markAllAsTouched(); // Mark all fields as touched to trigger validation messages
      return;
    }

    const formValue = this.inspectionForm.value;
    const response = this.buildResponse(formValue);
    this.loading = true;

    this.checklistService.postInspection(response).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.showForm = false;
        this.showSuceessSection = true;
        this.showUnAuthorised = false;
        this.toaster.success('Inspection completed successfully');
      },
      error: (err: any) => {
        this.loading = false;
        if (err.status == '400') {
          console.log('error', err);

          this.toaster.error(err.error);
        } else {
          this.showForm = false;
          this.showSuceessSection = false;
          this.showUnAuthorised = true;
          this.unAuthorisedMessage = err.statusText;
        }
      },
    });
  }
  buildResponse2(formValue: any) {
    const result: any = {
      checklistId: this.checklistId,
      identifier: this.identifier,
      inspectionStatus: 'DRAFT',
      name: this.inspectionName ? this.inspectionName : null,
      categories: [],
    };

    this.formData.categories.forEach((category, i) => {
      const categoryResult: any = {
        id: category.id,
        name: category.name,
        description: category.description,
        answers: [],
      };

      // Iterate over the questions and collect valid answers
      category.questions.forEach((element, j) => {
        const answerValue = formValue['category-' + i + '-element-' + j];

        // Check if answer is valid (not empty string, null, or undefined)
        if (
          answerValue !== '' &&
          answerValue !== null &&
          answerValue !== undefined
        ) {
          const elementResult: any = {
            id: element.id,
            title: element.title,
            answer: answerValue,
            comment: element.comments || '', // Comment can still be optional
            attachments: element.attachments || [], // Collect attachments
            attachmentsList: element.attachmentsList || [], // Attachments list
          };

          categoryResult.answers.push(elementResult); // Push only valid answers
        }
      });

      // Only push categories that have at least one valid answer
      if (categoryResult.answers.length > 0) {
        result.categories.push(categoryResult);
      }
    });

    return result;
  }

  buildResponse3(formValue: any) {
    const result: any = {
      checklistId: this.checklistId,
      identifier: this.identifier,
      inspectionStatus: 'DRAFT',
      name: this.inspectionName ? this.inspectionName : null,
      categories: [],
    };

    this.formData.categories.forEach((category, i) => {
      const categoryResult: any = {
        id: category.id,
        name: category.name,
        description: category.description,
        answers: [],
      };

      category.questions.forEach((element, j) => {
        const elementResult: any = {
          id: element.id,
          title: element.title,
          answer: formValue['category-' + i + '-element-' + j] || null,
          comment: element.comments || '',
          attachments: element.attachments || [], // Collect attachments here
          attachmentsList: null, // Collect attachments here
        };

        categoryResult.answers.push(elementResult);
      });

      result.categories.push(categoryResult);
    });

    return result;
  }

  buildResponse(formValue: any) {
    const result: any = {
      checklistId: this.checklistId,
      identifier: this.identifier,
      name: this.inspectionName ? this.inspectionName : null,
      categories: [],
    };

    this.formData.categories.forEach((category, i) => {
      const categoryResult: any = {
        id: category.id,
        name: category.name,
        description: category.description,
        answers: [],
      };

      category.questions.forEach((element, j) => {
        const elementResult: any = {
          id: element.id,
          title: element.title,
          answer: formValue['category-' + i + '-element-' + j] || null,
          comment: element.comments || '',
          attachments: element.attachments || [], // Collect attachments here
          attachmentsList: null, // Collect attachments here
        };

        categoryResult.answers.push(elementResult);
      });

      result.categories.push(categoryResult);
    });

    return result;
  }

  openImageUpload(categoryIndex: number, elementIndex: number) {
    this.currentCategoryIndex = categoryIndex;
    this.currentElementIndex = elementIndex;

    const modalRef = this.modalService.open(ImageUploadDrawComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.result.then(
      (dataUrl) => {
        if (dataUrl.base64) {
          // Initialize the attachments array if not already
          if (
            !this.formData.categories[this.currentCategoryIndex].questions[
              this.currentElementIndex
            ].attachmentsList
          ) {
            this.formData.categories[this.currentCategoryIndex].questions[
              this.currentElementIndex
            ].attachmentsList = []; // Initialize if undefined
          }

          // Push the dataUrl into the attachments
          this.formData.categories[this.currentCategoryIndex].questions[
            this.currentElementIndex
          ].attachmentsList.push(dataUrl.base64);

          if (
            !this.formData.categories[this.currentCategoryIndex].questions[
              this.currentElementIndex
            ].attachments
          ) {
            this.formData.categories[this.currentCategoryIndex].questions[
              this.currentElementIndex
            ].attachments = []; // Initialize if undefined
          }

          // Push the dataUrl into the attachments
          this.formData.categories[this.currentCategoryIndex].questions[
            this.currentElementIndex
          ].attachments.push(dataUrl.file);

          this.saveAsDraft();
        }
      },
      (reason) => {
        // Handle dismissal if necessary
      }
    );
  }

  createCategoryForm(): FormGroup {
    return this.fb.group({
      name: [''],
      description: [''],
      questions: this.fb.array([]), // Questions will be added dynamically
    });
  }

  createQuestionForm(): FormGroup {
    return this.fb.group({
      id: [''],
      title: [''],
      answer: [''],
      comment: [''],
      attachments: this.fb.array([]), // For attachments if necessary
    });
  }

  getImage(img) {
    if (img) return this.cixUrl + `/general/fetch?filename=` + img;
    return '';
  }

  removeImage(
    categoryIndex: number,
    questionIndex: number,
    attachmentIndex: number
  ) {
    // Remove the image from formData
    this.formData.categories[categoryIndex].questions[
      questionIndex
    ].attachments.splice(attachmentIndex, 1);

    // Optionally, you may need to remove it from the form group as well if you have form controls for attachments
    // (if this is relevant, adjust accordingly).
  }

  onRadioChange() {
    this.saveAsDraft();
  }
  onSelectChanged() {
    console.log('Changed');
    this.saveAsDraft();
  }

  viewAttachments(file, ext) {
    this.modalRef = this.modalService.open(UniversalFileViewerComponent, {
      centered: true,
      scrollable: true,
    });
    const componentInstance: UniversalFileViewerComponent =
      this.modalRef.componentInstance;
    componentInstance.url = this.cixUrl + '/general/fetch?filename=' + file;
    componentInstance.extension = ext;
    componentInstance.fileName = file;
    this.modalRef.result
      .then((result) => {
        if (result === '') {
        }
      })
      .catch((reason) => {});
  }

  getFileExtension(fileName: string): string {
    if (fileName != null) {
      const parts = fileName.split('.');
      const extension = parts[parts.length - 1];
      return extension;
    }
    return null;
  }

  updateWidth(value: string) {
    const calculatedWidth = Math.min(
      value.length * 12,
      window.innerWidth < 768 ? 300 : 700
    ); // Limit for mobile vs desktop
    this.inputWidth = `${calculatedWidth}px`;
  }

  showInput() {
    this.isInputVisible = !this.isInputVisible;
  }
}
