import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.scss'],
})
export class CommonTableComponent {
  // Inputs to dynamically pass the table data and headers
  @Input() tableHeaders: string[] = []; // Dynamic headers
  @Input() tableData: any[] = []; // API response data
  @Input() badgeColumns: string[] = []; // Columns to be treated as badges

  // Function to get the badge class based on the value (for status or other badge columns)
  getBadgeClass(value: string): string {
    switch (value.toLowerCase()) {
      case 'approved':
        return 'badge badge-success';
      case 'pending':
        return 'badge badge-warning';
      case 'rejected':
        return 'badge badge-danger';
      default:
        return 'badge badge-secondary';
    }
  }
}
