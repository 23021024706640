<div class="loading-overlay" *ngIf="loading">
  <div class="loading"></div>
</div>

<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Inspection</h5>
    <button
      (click)="activeModal.dismiss()"
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    ></button>
  </div>
  <div *ngIf="needMoreInfo" class="modal-body m-0 p-0">
    <div *ngIf="needMoreInfo" class="card">
      <div class="card-header">
        <ul
          class="nav nav-tabs card-header-tabs"
          data-bs-toggle="tabs"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <a
              href="#generate"
              class="nav-link active"
              data-bs-toggle="tab"
              aria-selected="true"
              role="tab"
              ><!-- Download SVG icon from http://tabler-icons.io/i/home -->
              <i class="ti ti-qrcode icon me-2"></i>
              Generate Inspection URL</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              href="#List"
              class="nav-link"
              data-bs-toggle="tab"
              aria-selected="false"
              role="tab"
              tabindex="-1"
              ><!-- Download SVG icon from http://tabler-icons.io/i/user -->
              <i class="ti ti-list icon me-2"></i>
              List URL's</a
            >
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content">
          <div class="tab-pane active show" id="generate" role="tabpanel">
            <div class="mb-3">
              <div>
                <label class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="radios-inline"
                    [(ngModel)]="selectedOption"
                    (ngModelChange)="onOptionChange('ME')"
                    value="me"
                  />
                  <span class="form-check-label">Generate URL for me</span>
                </label>
                <label class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="radios-inline"
                    [(ngModel)]="selectedOption"
                    (ngModelChange)="onOptionChange('OTHERS')"
                    value="other"
                  />
                  <span class="form-check-label"
                    >Generate URL for other user</span
                  >
                </label>
              </div>
            </div>

            <div *ngIf="selectedOption === 'me'"></div>

            <div *ngIf="selectedOption === 'other'"></div>

            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div *ngIf="selectedOption === 'other'" class="mb-3">
                <app-user-property-filter-dropdown
                  [selectedUserIds]="getUserId()"
                  (otherSelectedEvent)="participantOtherSelect()"
                  [otherUserOption]="true"
                  [userOption]="true"
                  [siteId]="siteId"
                  [dropdownHeight]="200"
                  (userSelectedEvent)="participantUserSelect($event)"
                ></app-user-property-filter-dropdown>
                <div
                  *ngIf="isUserSelectionInvalid"
                  class="invalid-feedback d-block"
                >
                  Please select a user.
                </div>
              </div>

              <div
                *ngIf="user?.email && selectedOption === 'other'"
                class="d-flex gap-2 text-muted fs-4 pb-2"
              >
                <div>
                  <span *ngIf="user?.email">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-mail ps-1"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"
                      />
                      <path d="M3 7l9 6l9 -6" />
                    </svg>
                  </span>
                  {{ user?.email }}
                </div>
                <div>
                  <span *ngIf="user?.phone">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-phone ps-1"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"
                      />
                    </svg>
                  </span>
                  {{ user?.phone }}
                </div>
                <div>
                  <i
                    class="ti ti-circle-minus text-red fs-2 cursor-pointer"
                    (click)="deleteParticipant()"
                  ></i>
                </div>
              </div>

              <!-- Comment Field -->
              <div class="mb-3">
                <textarea
                  formControlName="comment"
                  class="form-control w-100"
                  rows="1"
                  placeholder="Enter your comments..."
                ></textarea>
                <div
                  *ngIf="
                    form.get('comment').invalid && form.get('comment').touched
                  "
                >
                  <div class="invalid-feedback">Comment required.</div>
                </div>
              </div>

              <!-- Dynamic Key-Value Pair Fields -->
              <div class="mb-3" formArrayName="customValue">
                <div
                  *ngFor="
                    let keyValuePair of customValueFormArray.controls;
                    let i = index
                  "
                >
                  <div
                    class="d-flex gap-2 column-gap-2 align-items-center mb-2"
                    [formGroupName]="i"
                  >
                    <div>
                      <input
                        placeholder="Enter Key"
                        style="width: 300px"
                        class="form-control"
                        formControlName="key"
                        type="text"
                      />
                      <div
                        *ngIf="isFieldInvalid(i, 'key')"
                        class="invalid-feedback"
                      >
                        Key required
                      </div>
                    </div>

                    <div>
                      <input
                        placeholder="Enter Value"
                        style="width: 300px"
                        class="form-control"
                        formControlName="value"
                        type="text"
                      />
                      <div
                        *ngIf="isFieldInvalid(i, 'value')"
                        class="invalid-feedback"
                      >
                        Value required
                      </div>
                    </div>

                    <!-- Remove button, not visible for the first key-value pair -->
                    <div>
                      <i
                        *ngIf="i > 0"
                        (click)="removeKeyValuePair(i)"
                        class="ti ti-circle-minus text-red cursor-pointer fs-2"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="d-flex justify-content-between align-items-center mt-2"
              >
                <!-- Add New Key-Value Pair -->
                <button
                  type="button"
                  (click)="addKeyValuePair()"
                  class="btn btn-primary btn-sm"
                >
                  <i class="ti ti-plus fs-2"></i><span class="ms-2"> Add </span>
                </button>

                <!-- Submit Button -->
                <button class="btn btn-primary" type="submit">
                  Generate URL
                </button>
              </div>
            </form>
          </div>
          <div class="tab-pane" id="List" role="tabpanel">
            <div
              *ngIf="inspectionData.length <= 0"
              class="empty"
              style="height: calc(100vh - 200px)"
            >
              <app-error-template
                [errorTitle]="'No URL created'"
              ></app-error-template>
            </div>
            <div
              *ngIf="inspectionData.length > 0"
              class="table-responsive overflow-auto"
              style="height: calc(100vh - 200px)"
            >
              <table class="table table-vcenter card-table">
                <thead style="position: sticky; top: 0; z-index: 1">
                  <tr>
                    <th></th>
                    <th>Instance Name</th>
                    <th>Comment</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let inspection of inspectionData">
                    <td
                      (click)="copyToClipboard(inspection.link)"
                      class="fs-5 cursor-pointer"
                    >
                      <div>
                        <qrcode
                          [qrdata]="inspection.link"
                          [allowEmptyString]="true"
                          [width]="80"
                          [errorCorrectionLevel]="'M'"
                        ></qrcode>
                      </div>
                    </td>
                    <td class="text-primary">
                      <div>
                        <div>
                          {{ inspection?.instanceName }}
                        </div>
                      </div>
                    </td>
                    <td class="text-secondary">
                      <div style="width: 150px">
                        <div>
                          {{ inspection?.comment }}
                        </div>
                      </div>
                    </td>
                    <td class="fs-5">
                      <div>
                        <span
                          *ngIf="
                            inspection?.instanceStatus ===
                            'READY_FOR_INSPECTION'
                          "
                          class="badge bg-blue-lt"
                          >Ready for inspection</span
                        >
                        <span
                          *ngIf="inspection?.instanceStatus === 'IN_PROGRESS'"
                          class="badge bg-yellow-lt"
                          >In Progress</span
                        >
                        <span
                          *ngIf="inspection?.instanceStatus === 'EXPIRED'"
                          class="badge bg-warning-lt"
                          >Expired</span
                        >
                        <span
                          *ngIf="inspection?.instanceStatus === 'FAILED'"
                          class="badge bg-red-lt"
                          >Failed</span
                        >
                        <span
                          *ngIf="inspection?.instanceStatus === 'COMPLETED'"
                          class="badge bg-green-lt"
                          >Completed</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isGenerated" class="modal-body">
    <div class="d-flex gap-3 align-items-center">
      <div *ngIf="urlData?.link">
        <qrcode
          [qrdata]="urlData?.link"
          [allowEmptyString]="true"
          [width]="150"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
      </div>
      <div>
        <div>
          <h4>
            Scan this QR code to access and utilize the checklist across various
            applications in your hotel operations. Streamline your workflow and
            enhance efficiency!
          </h4>
        </div>

        <div class="d-flex gap-2">
          <button
            (click)="copyToClipboard(urlData?.link)"
            type="button"
            class="btn btn-primary"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-copy text-white me-2"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"
              />
              <path
                d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"
              />
            </svg>
            Copy to clipboard
          </button>

          <button
            (click)="goToUrl(urlData?.link)"
            type="button"
            class="btn btn-primary"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-copy text-white me-2"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
              <path d="M3.6 9h16.8" />
              <path d="M3.6 15h16.8" />
              <path d="M11.5 3a17 17 0 0 0 0 18" />
              <path d="M12.5 3a17 17 0 0 1 0 18" />
            </svg>
            Go to URL
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="modal-footer">
    <button (click)="this.activeModal.dismiss()" type="button" class="btn me-auto" data-bs-dismiss="modal">
      Close
    </button>

  </div> -->
</div>
