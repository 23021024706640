import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {
  DepartmentDropdownResponse,
  Team,
  User,
} from 'src/app/modules/user/models/user.models';
import { Properties } from '../../models/notification.models';
import { BrandInfo } from 'src/app/core/services/property.service';
import { log } from 'console';

@Component({
  selector: 'app-user-property-filter-dropdown',
  templateUrl: './user-property-filter-dropdown.component.html',
  styleUrls: ['./user-property-filter-dropdown.component.scss'],
})
export class UserPropertyFilterDropdownComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() siteId: string;
  @Input() siteIds: any[];
  @Input() selectFirstItem: boolean = false;
  // input already selected data to be shown in the dropdown
  @Input() selectedUserIds: string[] = []; //Accept all the selected userIds so that the dropdown won't show it again
  @Input() selectedTeamIds: string[] = []; //Accept all the selected teamIds so that the dropdown won't show it again
  @Input() selectedPropertytIds: string[] = [];
  @Input() selectedBrandIds: string[] = [];
  @Input() selectedDepartmentIds: string[] = [];
  @Input() divisionId: string;
  @Input() excludedUserIds: string[] = [];

  // check for type of dropdowns needed
  @Input() otherUserOption: boolean = false;
  @Input() userOption: boolean = false;
  @Input() teamOption: boolean = false;
  @Input() propertyOption: boolean = false;
  @Input() brandOption: boolean = false;
  @Input() departmentOption: boolean = false;
  @Input() includeCompanyAdmin: boolean = true;
  // style properties
  @Input() dropdownHeight: number = 355;
  @Input() filterWidth: number = 200;

  // cloud filter for site.which cloud the data should fetch from
  @Input() cloud: string;

  // multiselect enabled or not
  @Input() multiple: boolean = false;

  // check if all property option is needed
  @Input() hasAllPropertyOption: boolean = false;
  @Input() hasAllBrandOption: boolean = false;

  @Input() isValid: boolean;
  @Input() department: any;

  // emmit event when a value is selected in the dropdown
  @Output() userSelectedEvent = new EventEmitter<User | User[]>();
  @Output() teamSelectedEvent = new EventEmitter<Team | Team[]>();
  @Output() propertySelectedEvent = new EventEmitter<
    Properties | Properties[]
  >();
  @Output() brandSelectedEvent = new EventEmitter<BrandInfo | BrandInfo[]>();
  @Output() otherSelectedEvent = new EventEmitter<boolean>();
  @Output() departmentSelectedEvent = new EventEmitter<
    DepartmentDropdownResponse | DepartmentDropdownResponse[]
  >();

  public displaySelectedData: string;
  public placeHolder: string = 'Select User';
  public displaySelectedArray = [];
  private dropdownButton: HTMLElement;
  private dropdownMenu: HTMLElement;
  private showListener: () => void;
  private hideListener: () => void;
  constructor(private el: ElementRef, private renderer: Renderer2) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['siteId']?.currentValue) {
      this.siteId = changes['siteId'].currentValue;
    }

    if (changes['divisionId']?.currentValue) {
      this.divisionId = changes['divisionId'].currentValue;
    }

    if (changes['selectedUserIds']?.currentValue) {
      this.selectedUserIds = changes['selectedUserIds'].currentValue;
    }

    if (changes['excludedUserIds']?.currentValue) {
      this.excludedUserIds = changes['excludedUserIds'].currentValue;
    }

    if (changes['selectedTeamIds']?.currentValue) {
      this.selectedTeamIds = changes['selectedTeamIds'].currentValue;
    }
  }

  ngOnInit(): void {
    if (this.userOption) {
      this.placeHolder = 'Select User';
    } else if (this.teamOption) {
      this.placeHolder = 'Select Team';
    } else if (this.propertyOption) {
      this.placeHolder = 'Select Property';
    } else if (this.brandOption) {
      this.placeHolder = 'Select Brand';
    } else if (this.departmentOption) {
      this.placeHolder = 'Select Department';
    }

    // if (this.defaultSelectedProperty) {
    //   this.setDisplayDataProperty(this.defaultSelectedProperty)
    // }
  }

  emmitUserSelectedEvent(user: User | User[]) {
    this.setDisplayDataUser(user);
    this.userSelectedEvent.emit(user);
  }

  emmitTeamSelectedEvent(team: Team | Team[]) {
    this.setDisplayDataTeam(team);
    this.teamSelectedEvent.emit(team);
  }

  emmitPropertySelectedEvent(property: Properties | Properties[]) {
    this.setDisplayDataProperty(property);
    this.propertySelectedEvent.emit(property);
  }

  emmitBrandSelectedEvent(brand: BrandInfo | BrandInfo[]) {
    this.setDisplayDataBrand(brand);
    this.brandSelectedEvent.emit(brand);
  }

  emmitOtherSelectedEvent(event: boolean) {
    this.otherSelectedEvent.emit(event);
  }

  emmitDepartmentSelectedEvent(
    department: DepartmentDropdownResponse | DepartmentDropdownResponse[]
  ) {
    this.setDisplayDataDepartment(department);
    this.departmentSelectedEvent.emit(department);
  }

  setDisplayDataUser(user: User | User[]) {
    if (!Array.isArray(user)) {
      this.displaySelectedData = user.displayName;
    } else {
      this.displaySelectedData = user.map((u) => u.displayName).join(',');
      this.displaySelectedArray = user.map((u) => u.displayName);
    }
  }

  setDisplayDataTeam(team: Team | Team[]) {
    if (!Array.isArray(team)) {
      this.displaySelectedData = team.name;
    } else {
      this.displaySelectedData = team.map((t) => t.name).join(',');
    }
  }

  setDisplayDataProperty(property: Properties | Properties[]) {
    console.log(property);

    if (!Array.isArray(property)) {
      this.displaySelectedData = property?.entityName;
    } else {
      if (property.length > 0) {
        this.displaySelectedData = property.map((p) => p.entityName).join(',');
        this.displaySelectedArray = property.map((u) => u.entityName);
      } else {
        this.displaySelectedData = '';
        this.displaySelectedArray = [];
      }
    }
  }

  setDisplayDataBrand(brand: BrandInfo | BrandInfo[]) {
    if (!Array.isArray(brand)) {
      this.displaySelectedData = brand.name;
    } else {
      this.displaySelectedData = brand.map((b) => b.name).join(',');
    }
  }

  setDisplayDataDepartment(
    department: DepartmentDropdownResponse | DepartmentDropdownResponse[]
  ) {
    if (!Array.isArray(department)) {
      this.displaySelectedData = department.displayName;
    } else {
      this.displaySelectedData = department.map((d) => d.displayName).join(',');
    }
  }

  ngAfterViewInit() {
    this.dropdownButton =
      this.el.nativeElement.querySelector('#dropdownButton');
    this.dropdownMenu = this.el.nativeElement.querySelector('#dropdownMenu');

    // Add event listeners for Bootstrap dropdown events and store the listener references
    this.showListener = this.renderer.listen(
      this.dropdownButton,
      'show.bs.dropdown',
      this.onShow.bind(this)
    );
    this.hideListener = this.renderer.listen(
      this.dropdownButton,
      'hidden.bs.dropdown',
      this.onHide.bind(this)
    );
  }

  onShow(event: any) {
    // Move the dropdown menu to the body
    document.body.appendChild(this.dropdownMenu);

    // Get the position of the dropdown button
    const rect = this.dropdownButton.getBoundingClientRect();

    // Position the dropdown near the button
    this.renderer.setStyle(this.dropdownMenu, 'position', 'absolute');
    this.renderer.setStyle(
      this.dropdownMenu,
      'top',
      `${rect.bottom + window.scrollY}px`
    );
    this.renderer.setStyle(
      this.dropdownMenu,
      'left',
      `${rect.left + window.scrollX}px`
    );
    this.renderer.setStyle(this.dropdownMenu, 'z-index', '100000');
  }

  onHide(event: any) {
    // Move the dropdown back to its original location inside the component
    this.renderer.appendChild(
      this.dropdownButton.parentElement,
      this.dropdownMenu
    );
  }

  ngOnDestroy() {
    console.log('ngOnDestroy');

    // Clean up event listeners when the component is destroyed
    this.renderer.listen(this.dropdownButton, 'show.bs.dropdown', null);
    this.renderer.listen(this.dropdownButton, 'hidden.bs.dropdown', null);
    if (document.body.contains(this.dropdownMenu)) {
      this.renderer.removeChild(document.body, this.dropdownMenu);
    }
  }
}
