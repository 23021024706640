// ------------------- up comming api calls ------------------

import { BaseFilter } from "../../log-management/models/department-log.models";
import { User } from "../../user/models/user.models";

export class Reservation {
  reservation: reservation[];
  room: room[];
  hk: hk[];
  fo: fo[];
}
export class reservation {
  status: String;
  count: String;
}
export class room {
  status: string;
  count: string;
}
export class hk {
  status: String;
  count: String;
}
export class fo {
  status: String;
  count: String;
}

export class RoomStatusInfoSummeryReques extends BaseFilter {
  companyid: String;
  summaryForStatuses: SummaryForStatuses[];

}

export class RoomStatusInfoSearch {
  companyid: String;
  summaryForStatuses: SummaryForStatuses[];
}

export enum SummaryForStatuses {
  ROOM = 'ROOM',
  RESERVATION = "RESERVATION",
  FO = "FO",
}

export class RoomStatusInformations {
  status: String;
  color: string;
}



export class RoomStatReport {
  status: StatusDetails[];
}
export class StatusDetails {
  room: any;
}


export class GuestDetailsRequest {
  status: String[];
  room: String;
  siteId: String;
  companyId: String;
}

export class GuestDetailsResponse {
  _id: String;
  firstName: string;
  lastName: string;
  reservationId: String;
  subReservationId: String;
  status: String;
  phone: String;
  email: String;
  room: String;
  companyCRMId: String;
  checkInTime: string;
  checkOutTime: string;
}

export class CleaningHistoryRequest {
  companyId: String;
  siteId: String;
  //todo type conflict
  roomId: any;
}

export class CleaningHistoryResponse {
  createdOn: Date;
  taskId: Number;
  assignedToId: String;
  location: String;
  cleaningProfile: String;
  resolutionTimeInMinutes: Number;
  companyId: String;
  siteId: String;
  assignedToInfo: User
}


export class SpecialRequest {
  companyId: String;
  siteId: String;
  roomId: any;
  date: Date;
}

export class SpecialRequestResponse {
  id: Number;
  profile: String;
  specialNotes?: String;
}
