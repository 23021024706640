import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { CommonuseService } from '../../service/commonuse.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserDetailsComponent } from 'src/app/modules/log-management/pages/hr-connect/user-details/user-details.component';
import { ChecklistService } from 'src/app/modules/checklist/services/checklist.service';

@Component({
  selector: 'app-generate-inspection-url',
  templateUrl: './generate-inspection-url.component.html',
  styleUrls: ['./generate-inspection-url.component.css'],
})
export class GenerateInspectionUrlComponent implements OnInit {
  loading: boolean = false;
  @Input() needMoreInfo: boolean = false;
  @Input() checklistId: any;
  selectedOption = 'me'; // Default option
  form: FormGroup;
  private modalRef: NgbModalRef;
  siteId: any;
  user: any;
  isUserSelectionInvalid: boolean = false;
  @Input() reference: any = null;
  @Input() referenceId: any = null;
  @Input() initiatedById: any = null;
  urlData;
  currentUser: any;
  isGenerated: boolean = false;
  @Output() inspectionUrlData: EventEmitter<any> = new EventEmitter();
  companyId: string;
  inspectionData: any;
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private authService: AuthService,
    private toaster: ToastrService,
    private commonService: CommonuseService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private checklistService: ChecklistService
  ) {
    this.form = this.fb.group({
      comment: [null],
      customValue: this.fb.array([this.createKeyValuePair(false)]), // One default key-value pair
      checklistId: this.checklistId,
      userId: [null],
      name: [null],
      email: [null],
      phone: [null],
      role: [null],
      reference: [null],
      referenceId: [null],
      initiatedById: [null],
      siteId: [null],
    });
  }

  ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();

    if (
      this.currentUser.role == 'COMPANY_ADMIN' ||
      this.currentUser.role == 'COMPANY_USER'
    ) {
      const currentUserSession = this.authService.getEntityCompany();
      this.siteId = null;
    } else {
      const currentUserSession = this.authService.getCurrentUser();
      this.siteId = currentUserSession.siteIds[0];
    }
    this.initiatedById = this.authService.getCurrentUserId();

    if (this.needMoreInfo) {
      this.onOptionChange('ME');

      this.getAllInspections(this.checklistId);
    } else {
      const result = {
        checklistId: this.checklistId,
        reference: this.reference ? this.reference : null,
        referenceId: this.referenceId ? this.referenceId : null,
        initiatedById: this.initiatedById,
        siteId: this.siteId,
      };
      this.generateUrl(result);
    }
  }

  getAllInspections(id) {
    this.companyId = this.authService.getSession()?.companyId;
    this.checklistService
      .getAllInspections(id, this.companyId, this.initiatedById)
      .subscribe({
        next: (res: any) => {
          this.loading = false;
          this.inspectionData = res.data;
        },
        error: (err: any) => {
          console.log(err);
          this.toaster.error(err.statusText);
          this.loading = false;
        },
      });
  }

  // Helper function to create a key-value pair form group
  createKeyValuePair(isMandatory: boolean): FormGroup {
    return this.fb.group({
      key: ['', isMandatory ? Validators.required : []],
      value: ['', isMandatory ? Validators.required : []],
    });
  }

  // Function to add a new key-value pair
  addKeyValuePair(): void {
    // Make the first key-value pair mandatory if it's not already mandatory
    this.makeFirstKeyValueMandatory();

    // Add a new key-value pair with Validators.required
    this.customValueFormArray.push(this.createKeyValuePair(true));
  }

  // Function to make the first key-value pair mandatory
  makeFirstKeyValueMandatory(): void {
    const firstKeyValuePair = this.customValueFormArray.at(0);
    firstKeyValuePair.get('key').setValidators(Validators.required);
    firstKeyValuePair.get('key').updateValueAndValidity();
    firstKeyValuePair.get('value').setValidators(Validators.required);
    firstKeyValuePair.get('value').updateValueAndValidity();
  }

  // Function to make the first key-value pair optional if it is the only one remaining
  makeFirstKeyValueOptional(): void {
    const firstKeyValuePair = this.customValueFormArray.at(0);
    firstKeyValuePair.get('key').clearValidators();
    firstKeyValuePair.get('key').updateValueAndValidity();
    firstKeyValuePair.get('value').clearValidators();
    firstKeyValuePair.get('value').updateValueAndValidity();
  }

  // Function to remove a key-value pair by index (except the first one)
  removeKeyValuePair(index: number): void {
    if (index > 0) {
      this.customValueFormArray.removeAt(index);
    }
    // If there's only one key-value pair left, make it optional again
    if (this.customValueFormArray.length === 1) {
      this.makeFirstKeyValueOptional();
    }
  }

  // Check if the field is invalid and touched for showing validation errors
  isFieldInvalid(index: number, fieldName: string): boolean {
    const control = this.customValueFormArray.at(index).get(fieldName);
    return control.invalid && control.touched;
  }

  // Handle form submission

  onSubmit(): void {
    if (this.selectedOption === 'other' && !this.user) {
      this.isUserSelectionInvalid = true; // Show validation message
      return; // Prevent form submission
    }
    if (this.form.invalid) {
      this.form.markAllAsTouched(); // Show all errors
      return;
    }

    const formValue = this.form.value;
    let customValue = {};
    let isCustomValueEmpty = true;

    // Loop through the key-value pairs
    formValue.customValue.forEach((pair) => {
      if (pair.key && pair.value) {
        customValue[pair.key] = pair.value;
        isCustomValueEmpty = false; // If there's at least one valid pair
      }
    });

    // If no key-value pairs are provided, set customValue to null
    if (isCustomValueEmpty) {
      customValue = null;
    }
    const result = {
      comment: formValue.comment,
      customValue: customValue,
      checklistId: this.checklistId,
      userId: this.user.userId,
      name: this.user.name,
      email: this.user.email,
      phone: this.user.phone,
      role: this.user.role,
      reference: this.reference,
      referenceId: this.referenceId,
      initiatedById: this.initiatedById,
      siteId: this.siteId,
    };

    this.generateUrl(result);
  }

  generateUrl(payload) {
    this.checklistService.generateUrl(payload).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.urlData = res.data;
        this.inspectionUrlData.emit(res.data);
        if (this.urlData.link) {
          this.toaster.success('Link generated successfully');
          this.isGenerated = true;
          this.needMoreInfo = false;
        } else {
          this.isGenerated = false;
          this.needMoreInfo = true;
        }
      },
      error: (err: any) => {
        console.log(err);
        this.toaster.error(err.statusText);
        this.loading = false;
      },
    });
  }

  // Getter for customValue form array
  get customValueFormArray(): FormArray {
    return this.form.get('customValue') as FormArray;
  }

  participantUserSelect(user: any) {
    this.user = null;
    if (user) {
      this.user = {
        name: user?.displayName ? user?.displayName : null,
        email: user?.email.emailId ? user?.email.emailId : null, // Assuming the email is available
        phone: user?.userEntity?.communication?.primaryContact?.phone
          ? user?.userEntity?.communication?.primaryContact?.phone
          : null, // Assuming the phone is available
        userId: user.id ? user.id : null,
      };
      this.isUserSelectionInvalid = false; // Reset validation flag
    }
  }

  participantOtherSelect() {
    this.user = null;
    this.modalRef = this.modalService.open(UserDetailsComponent, {
      size: 'sm',
      centered: true,
      scrollable: true,
    });
    const componentInstance: UserDetailsComponent =
      this.modalRef.componentInstance;

    this.modalRef.result
      .then((result) => {
        this.user = {
          name: result.name,
          email: result?.email,
          phone: result?.number,
          userId: null,
        };
      })
      .catch((reason) => {});
  }

  deleteParticipant() {
    this.user = null;
  }

  getUserId() {
    if (this.user?.userId) {
      return this.user?.userId;
    } else {
      return null;
    }
  }

  onOptionChange(type) {
    if (type === 'ME') {
      let currentUser = this.authService.getCurrentUser();
      console.log(currentUser);

      this.user = {
        name: currentUser?.userProfile?.displayName,
        email: currentUser?.email?.emailId,
        phone: currentUser?.phone?.phoneNumber,
        userId: currentUser?.id,
        role: currentUser?.role,
      };
    }
  }

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(
      () => {
        this.toaster.success('Url copied to clipboard');
      },
      (err) => {
        console.error('Could not copy text: ', err);
      }
    );
  }

  goToUrl(url: string): void {
    window.open(url, '_blank');
  }
}
